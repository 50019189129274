import { exhaustive } from "exhaustive";
import { apiDelete, apiGet, apiPostGet } from "fetchApi";
import { TCommunity } from "utils/ecommerseTypes";
import { TPaginationActionData } from "utils/paginationStore";

export interface IPublicEventSubscription {
    id: number;
    created: string;
    public_event_type: TPublicEventType;
}

export interface IPublicEvent {
    id: number;
    created: string;
    context_content_type: {
        id: number;
        name: string;
        app_label: string;
    };
    context_object_id: number;
    community: TCommunity;
    public_event_type: TPublicEventType;
    description: string;
}

export interface IPublicEventsQueryParams {
    public_event_type?: string;
    period_before?: string;
    period_after?: string;
    domain_object_id?: string;
    ordering?: string;
}

export const TPublicEventTypes = [
    "subscription_terminated",
    "office_terminated",
    "subscription_updated",
    "office_updated",
    "membership_changes",
    "order_error",
    "unit4_error",
    "unit4_uploaded",
] as const;
export type TPublicEventType = typeof TPublicEventTypes[number];

export const fetchPublicEventSubscriptions = async (): Promise<IPublicEventSubscription[]> => {
    const returnData = await apiGet("/events/public-event-subscriptions/", {
        params: { page_size: 100 },
    });
    return exhaustive(returnData, "responseType", {
        Success: (it) => {
            return (it.data as TPaginationActionData<IPublicEventSubscription>).results;
        },
        Error: () => {
            return [];
        },
    });
};

export const createPublicEventSubscription = async (type: TPublicEventType): Promise<IPublicEventSubscription> => {
    const response = await apiPostGet<IPublicEventSubscription, object>("/events/public-event-subscriptions/", { public_event_type: type });
    return exhaustive(response, "responseType", {
        Success: (it) => {
            return it.data;
        },
        Error: () => {
            return {} as IPublicEventSubscription;
        },
    });
};

export const deletePublicEventSubscription = async (id: number): Promise<void> => {
    await apiDelete(`/events/public-event-subscriptions/${id}/`);
    return;
};

export const fetchPublicEventTypes = async (): Promise<TPublicEventType[]> => {
    interface PublicEventResponse {
        public_event_types: TPublicEventType[]
    }
    const response = await apiGet<PublicEventResponse>("/events/public-events/public-event-types/");

    return exhaustive(response, "responseType", {
        Success: (it) => {
            return it.data.public_event_types;
        },
        Error: () => {
            return [];
        },
    });
};
