import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { TMembershipId } from "reducers/memberships";
import { NotificationContext } from "contexts/notification";
import reducer, {
    IAction,
    IExternalMembership,
    IExternalMemberships,
    initialState,
} from "reducers/externalMemberships";
import { apiGet, apiPatchGet, apiPostGet } from "fetchApi";
import { exhaustive } from "exhaustive";

export const ExternalMembershipsContext = createContext<IExternalMemberships>({
    ...initialState,
});

export const ExternalMembershipsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IExternalMemberships, IAction>>(reducer, initialState);

    const fetchMembership = useCallback(
        async (membershipId: TMembershipId): Promise<IExternalMembership> => {
            dispatch({ type: "FETCH_EXTERNAL_MEMBERSHIP" });
            const returnData = await apiGet<IExternalMembership>(`/memberships/externals/${membershipId}/`);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_EXTERNAL_MEMBERSHIP_SUCCESS",
                        membership: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchMembership", error);
                    dispatch({ type: "FETCH_EXTERNAL_MEMBERSHIP_FAILURE" });
                    return {} as IExternalMembership;
                },
            });
        },
        [notification]
    );

    const updateMembership = useCallback(
        async (membershipId: TMembershipId, data: Record<string, unknown>): Promise<IExternalMembership> => {
            dispatch({ type: "UPDATE_EXTERNAL_MEMBERSHIP" });
            const returnData = await apiPatchGet<IExternalMembership, Record<string, unknown>>(`/memberships/externals/${membershipId}/`, data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_EXTERNAL_MEMBERSHIP_SUCCESS", data: it.data });
                    notification.enqueNotification("success_updateMembership");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateMembership", error);
                    dispatch({ type: "UPDATE_EXTERNAL_MEMBERSHIP_FAILURE" });
                    return {} as IExternalMembership;
                },
            });
        },
        [notification]
    );

    const createMembership = useCallback(
        async (data: Record<string, unknown>): Promise<IExternalMembership> => {
            dispatch({ type: "CREATE_EXTERNAL_MEMBERSHIP" });
            const returnData = await apiPostGet<IExternalMembership, Record<string, unknown>>("/memberships/externals/", data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "CREATE_EXTERNAL_MEMBERSHIP_SUCCESS", membership: it.data });
                    notification.enqueNotification("success_createExternalMembership");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_createExternalMembership", error);
                    dispatch({ type: "CREATE_EXTERNAL_MEMBERSHIP_FAILURE" });
                    return {} as IExternalMembership;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchMembership,
            updateMembership,
            createMembership,
        };
    }, [currentState, fetchMembership, updateMembership, createMembership]);

    return <ExternalMembershipsContext.Provider value={value}>{children}</ExternalMembershipsContext.Provider>;
};
