import { ProfileContext } from "contexts/profile";
import { apiPatch } from "fetchApi";
import React from "react";

const notificationMapping = {
    news: "newsarticle",
    representatives: "leaseinvitation",
};

interface IUseProfileNotifications {
    getRouteNotificationCount: (routePath: string) => number;
    getTargetNotificationCount: (target: string) => number;
    markAllRead: (routePath: string) => void;
}

export const useProfileNotifications = (): IUseProfileNotifications => {
    const { notificationCounts, notifications, fetchProfileNotifications, selectedOrganization } =
        React.useContext(ProfileContext);

    const getRouteNotificationCount = (routePath: string): number => {
        const target = notificationMapping[routePath];
        return target ? getTargetNotificationCount(target) : 0;
    };

    const getTargetNotificationCount = (target: string): number => {
        const notification = notificationCounts.find((n) => n.target === target);
        return notification ? notification.amount : 0;
    };

    const markAllRead = (routePath: string): void => {
        const target = notificationMapping[routePath];
        if (!target) {
            return;
        }

        const updateNotifications = new Promise((resolve, reject) => {
            const updated = notifications
                .filter((n) => n.target === target)
                .forEach((notification) => {
                    const data = {
                        unread: false,
                    };
                    apiPatch<object>(`/notifications/${notification.uuid}/`, data);
                });
            resolve(updated);
        });
        updateNotifications.then(() => {
            fetchProfileNotifications(selectedOrganization.org_nr);
        });
    };

    return {
        getRouteNotificationCount,
        getTargetNotificationCount,
        markAllRead,
    };
};
