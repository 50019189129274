import React, { useEffect } from "react";
import { AuthContext } from "contexts/auth";
import { getAuthStorage } from "reducers/auth";
import { isBefore, subMinutes } from "date-fns";
import { NotificationContext } from "contexts/notification";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FetchInterceptor } from "@mswjs/interceptors/fetch";

const excludedUrls = [
    "/auth/login/",
    "/auth/new-password/",
    "/auth/otp/sms/send/",
    "/auth/otp/verify/",
    "/auth/reset-password/",
    "/contact-persons/register/",
    "/auth/refresh-token/",
    "/auth/otp/totp/devices/",
    "/auth/otp/sms/devices/",
    "/auth/logout/",
    "/feature-flags/",
    "/auth/otp/backup-codes/",
    "/news/unsubscribe/",
];

interface IProps {
    children: JSX.Element;
}

function checkIfUrlShouldBeExcluded(url: string): boolean {
    let returnValue = false;
    excludedUrls.forEach((excludeUrl) => {
        if (url.includes(excludeUrl)) {
            returnValue = true;
        }
    });
    return returnValue;
}

const Interceptor: React.FC<IProps> = ({ children }) => {

    const { logout, refreshCredentials, isLoggedIn, clearAuthState } = React.useContext(AuthContext);
    const { ...notification } = React.useContext(NotificationContext);

    const interceptor = new FetchInterceptor();

    interceptor.apply();
    useEffect(() => {
        interceptor.on("request", ({ request }) => {
            if (!checkIfUrlShouldBeExcluded(request.url)) {
                const now = new Date();
                const session = getAuthStorage();
                const expires = session?.expires ? new Date(session?.expires) : undefined;

                const verifyExpiration = (!expires || isBefore(expires, now));
                if (verifyExpiration && isLoggedIn) {
                    if (isLoggedIn) {
                        notification.enqueNotification("warning_sessionEnded");
                    }

                    try {
                        logout();
                    } catch (error) {
                        return Promise.reject(error);
                    }

                    return Promise.reject(new Error("Session has expired"));
                }

                if (expires && now > subMinutes(expires, 45)) {
                    refreshCredentials();
                }
            }
            return;
        });

        interceptor.on(
            "response",
            ({ response }) => {

                if (response && response.status === 403) {
                    clearAuthState();
                } else if (
                    response &&
                    (response.status === 401 || response.statusText === "Unauthorized")
                ) {
                    logout();
                }
                return response;
            }
        );

    }, [logout, isLoggedIn]);

    return children;
};

export default Interceptor;
