import { exhaustive } from "exhaustive";
import { apiGet } from "fetchApi";
import React, { useMemo } from "react";
import reducer, { IAction, IFeature, IFeatureFlags, initialState } from "reducers/featureFlags";

export const FeatureFlagContext = React.createContext<IFeatureFlags>({
    ...initialState,
});

export const FeatureFlagProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [currentState, dispatch] = React.useReducer<React.Reducer<IFeatureFlags, IAction>>(reducer, initialState);

    React.useEffect(() => {
        (async () => {
            dispatch({ type: "FETCH_FLAGS" });
            const returnData = await apiGet<IFeature[]>("/feature-flags/");
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_FLAGS_SUCCESS",
                        features: it.data,
                    });
                },
                Error: () => {
                    dispatch({ type: "FETCH_FLAGS_FAILURE" });
                },
            });
        })();
    }, []);

    const value = useMemo(() => {
        return {
            ...currentState,
        };
    }, [currentState]);

    return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};
