import config from "config";
import { isBefore, parseISO } from "date-fns";
import { invalidateLocalCookie } from "utils/helpers";

export interface ISessionStorage {
    expires: string;
    otp_required?: boolean;
}

export interface IAuthorization {
    otp_required: boolean;
    hasTotpDevices: boolean;
    hasSMSDevices: boolean;
    key: string;
    expires_in: number;
}

export interface IBackupCode {
    token: string;
}

export interface ITotpDevice {
    id: number;
    name: string;
    confirmed: boolean;
    config_url: string;
}

export interface ISmsDevice {
    id: number;
    name: string;
    confirmed: boolean;
    number: string;
}

export interface IAuth {
    backupCodes: IBackupCode[];
    smsDevices?: ISmsDevice[];
    totpDevices?: ITotpDevice[];
    isLoggedIn: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    isSavingNewPassword: boolean;
    isSendingForgot: boolean;
    isFetchingBackupCodes: boolean;
    isGeneratingBackupCodes: boolean;
    isFetchingSmsDevices: boolean;
    isCreatingSmsDevice: boolean;
    isUpdatingSmsDevice: boolean;
    isDeletingSmsDevice: boolean;
    isSendingSms: boolean;
    isFetchingTotpDevices: boolean;
    isCreatingTotpDevice: boolean;
    isUpdatingTotpDevice: boolean;
    isDeletingTotpDevice: boolean;
    isVerifyingOtpCode: boolean;
    isResettingPassword: boolean;
    isRefreshingCredentials: boolean;
    login: (username: string, password: string) => Promise<IAuthorization>;
    logout: () => Promise<boolean>;
    setPassword: (
        password: string,
        rePassword: string,
        token: string,
        sendNewsByMail: boolean
    ) => Promise<Record<string, unknown> | boolean>;
    forgotPassword: (email: string) => Promise<boolean>;
    resetPassword: (password: string, uuid: string) => Promise<number>;
    fetchBackupCodes: () => Promise<IBackupCode[] | boolean>;
    generateBackupCodes: () => Promise<boolean>;
    fetchTotpDevices: () => Promise<boolean>;
    createTotpDevice: (name: string, key?: string) => Promise<boolean>;
    updateTotpDevice: (id: number, name: string) => Promise<boolean>;
    deleteTotpDevice: (id: number) => Promise<boolean>;
    fetchSmsDevices: () => Promise<boolean>;
    createSmsDevice: (name: string, number: string, key?: string) => Promise<boolean>;
    updateSmsDevice: (id: number, number: string, name?: string) => Promise<boolean>;
    deleteSmsDevice: (id: number) => Promise<boolean>;
    sendAuthenticationSMS: (key?: string) => Promise<string>;
    verifyOtpCode: (data: { token: string; key?: string }) => Promise<boolean>;
    clearAuthState: () => void;
    refreshCredentials: () => Promise<boolean>;
}

export const getAuthStorage = (): ISessionStorage | undefined => {
    try {
        const data = window.sessionStorage.getItem(config.authStorage);
        if (data) {
            return JSON.parse(data);
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
};

export const setAuthStorage = (value?: ISessionStorage | Record<string, unknown>): void => {
    window.sessionStorage.setItem(config.authStorage, JSON.stringify(value));
};
export const removeAuthStorage = (): void => window.sessionStorage.removeItem(config.authStorage);
export const removeLandingPageStorage = (): void => window.localStorage.removeItem("landingPage");

const validateAuthentication = (): boolean => {
    const sessionStore = getAuthStorage();
    return !!(sessionStore && isBefore(new Date(), parseISO(sessionStore.expires ?? "")));
};

export const initialState: IAuth = {
    totpDevices: undefined,
    smsDevices: undefined,
    backupCodes: [],
    isLoggedIn: validateAuthentication(),
    isLoggingIn: false,
    isLoggingOut: false,
    isSavingNewPassword: false,
    isSendingForgot: false,
    isFetchingBackupCodes: false,
    isGeneratingBackupCodes: false,
    isFetchingTotpDevices: false,
    isCreatingTotpDevice: false,
    isUpdatingTotpDevice: false,
    isDeletingTotpDevice: false,
    isFetchingSmsDevices: false,
    isCreatingSmsDevice: false,
    isUpdatingSmsDevice: false,
    isDeletingSmsDevice: false,
    isSendingSms: false,
    isVerifyingOtpCode: false,
    isResettingPassword: false,
    isRefreshingCredentials: false,
    login: async (): Promise<IAuthorization> => ({}) as IAuthorization,
    logout: async (): Promise<boolean> => false,
    setPassword: async (): Promise<boolean> => false,
    forgotPassword: async (): Promise<boolean> => false,
    resetPassword: async (): Promise<number> => 0,
    refreshCredentials: async (): Promise<boolean> => false,
    fetchBackupCodes: async (): Promise<boolean> => false,
    generateBackupCodes: async (): Promise<boolean> => false,
    fetchTotpDevices: async (): Promise<boolean> => false,
    createTotpDevice: async (): Promise<boolean> => false,
    updateTotpDevice: async (): Promise<boolean> => false,
    deleteTotpDevice: async (): Promise<boolean> => false,
    fetchSmsDevices: async (): Promise<boolean> => false,
    createSmsDevice: async (): Promise<boolean> => false,
    updateSmsDevice: async (): Promise<boolean> => false,
    deleteSmsDevice: async (): Promise<boolean> => false,
    sendAuthenticationSMS: async (): Promise<string> => "",
    verifyOtpCode: async (): Promise<boolean> => false,
    clearAuthState: (): void => { },
};

export type IAction =
    | { type: "FETCH_CREDENTIALS" }
    | { type: "FETCH_CREDENTIALS_SUCCESS"; sessionStore: ISessionStorage }
    | { type: "FETCH_CREDENTIALS_FAILURE" }
    | { type: "SET_PASSWORD" }
    | { type: "SET_PASSWORD_SUCCESS" }
    | { type: "SET_PASSWORD_FAILURE" }
    | { type: "FORGOT_PASSWORD" }
    | { type: "FORGOT_PASSWORD_SUCCESS" }
    | { type: "FORGOT_PASSWORD_FAILURE" }
    | { type: "FETCH_BACKUP_CODES" }
    | { type: "FETCH_BACKUP_CODES_SUCCESS"; codes: IBackupCode[] }
    | { type: "FETCH_BACKUP_CODES_FAILURE" }
    | { type: "GENERATE_BACKUP_CODES" }
    | { type: "GENERATE_BACKUP_CODES_SUCCESS"; codes: IBackupCode[] }
    | { type: "GENERATE_BACKUP_CODES_FAILURE" }
    | { type: "FETCH_TOTP_DEVICES" }
    | { type: "FETCH_TOTP_DEVICES_SUCCESS"; devices: ITotpDevice[] }
    | { type: "FETCH_TOTP_DEVICES_FAILURE" }
    | { type: "CREATE_TOTP_DEVICE" }
    | { type: "CREATE_TOTP_DEVICE_SUCCESS"; device: ITotpDevice }
    | { type: "CREATE_TOTP_DEVICE_FAILURE" }
    | { type: "UPDATE_TOTP_DEVICE" }
    | { type: "UPDATE_TOTP_DEVICE_SUCCESS"; id: number; device: ITotpDevice }
    | { type: "UPDATE_TOTP_DEVICE_FAILURE" }
    | { type: "DELETE_TOTP_DEVICE" }
    | { type: "DELETE_TOTP_DEVICE_SUCCESS"; id: number }
    | { type: "DELETE_TOTP_DEVICE_FAILURE" }
    | { type: "FETCH_SMS_DEVICES" }
    | { type: "FETCH_SMS_DEVICES_SUCCESS"; devices: ISmsDevice[] }
    | { type: "FETCH_SMS_DEVICES_FAILURE" }
    | { type: "CREATE_SMS_DEVICE" }
    | { type: "CREATE_SMS_DEVICE_SUCCESS"; device: ISmsDevice }
    | { type: "CREATE_SMS_DEVICE_FAILURE" }
    | { type: "UPDATE_SMS_DEVICE" }
    | { type: "UPDATE_SMS_DEVICE_SUCCESS"; id: number; device: ISmsDevice }
    | { type: "UPDATE_SMS_DEVICE_FAILURE" }
    | { type: "DELETE_SMS_DEVICE" }
    | { type: "DELETE_SMS_DEVICE_SUCCESS"; id: number }
    | { type: "DELETE_SMS_DEVICE_FAILURE" }
    | { type: "SEND_SMS" }
    | { type: "SEND_SMS_SUCCESS" }
    | { type: "SEND_SMS_FAILURE" }
    | { type: "VERIFY_OTP_CODE" }
    | { type: "VERIFY_OTP_CODE_SUCCESS"; sessionStore: Partial<ISessionStorage> }
    | { type: "VERIFY_OTP_CODE_FAILURE" }
    | { type: "CLEAR_CREDENTIALS" }
    | { type: "LOGOUT" }
    | { type: "LOGOUT_SUCCESS" }
    | { type: "LOGOUT_FAILURE" }
    | { type: "RESET_PASSWORD" }
    | { type: "RESET_PASSWORD_SUCCESS" }
    | { type: "RESET_PASSWORD_FAILURE" }
    | { type: "REFRESH_CREDENTIALS" }
    | { type: "REFRESH_CREDENTIALS_SUCCESS"; sessionStore: Partial<ISessionStorage> }
    | { type: "REFRESH_CREDENTIALS_FAILURE" };

function reducer(state: IAuth, action: IAction): IAuth {
    switch (action.type) {
        case "FETCH_CREDENTIALS":
            return { ...state, isLoggedIn: false, isLoggingIn: true };
        case "FETCH_CREDENTIALS_SUCCESS":
            if (action.sessionStore.otp_required === false) {
                setAuthStorage(action.sessionStore);
                return {
                    ...state,
                    isLoggedIn: true,
                    isLoggingIn: false,
                };
            }
            return {
                ...state,
                isLoggingIn: false,
            };
        case "FETCH_CREDENTIALS_FAILURE":
            return { ...state, isLoggedIn: false, isLoggingIn: false };

        case "SET_PASSWORD":
            return { ...state, isSavingNewPassword: true };
        case "SET_PASSWORD_SUCCESS":
        case "SET_PASSWORD_FAILURE":
            return { ...state, isSavingNewPassword: false };

        case "FORGOT_PASSWORD":
            return { ...state, isSendingForgot: true };
        case "FORGOT_PASSWORD_SUCCESS":
        case "FORGOT_PASSWORD_FAILURE":
            return { ...state, isSendingForgot: false };

        case "RESET_PASSWORD":
            return { ...state, isResettingPassword: true };
        case "RESET_PASSWORD_SUCCESS":
        case "RESET_PASSWORD_FAILURE":
            return { ...state, isResettingPassword: false };

        case "FETCH_BACKUP_CODES":
            return { ...state, isFetchingBackupCodes: true };
        case "FETCH_BACKUP_CODES_SUCCESS":
            return { ...state, isFetchingBackupCodes: false, backupCodes: action.codes };
        case "FETCH_BACKUP_CODES_FAILURE":
            return { ...state, isFetchingBackupCodes: false };

        case "GENERATE_BACKUP_CODES":
            return { ...state, isGeneratingBackupCodes: true };
        case "GENERATE_BACKUP_CODES_SUCCESS":
            return { ...state, isGeneratingBackupCodes: false, backupCodes: action.codes };
        case "GENERATE_BACKUP_CODES_FAILURE":
            return { ...state, isGeneratingBackupCodes: false };

        case "FETCH_TOTP_DEVICES":
            return { ...state, isFetchingTotpDevices: true };
        case "FETCH_TOTP_DEVICES_SUCCESS":
            return { ...state, isFetchingTotpDevices: false, totpDevices: action.devices };
        case "FETCH_TOTP_DEVICES_FAILURE":
            return { ...state, isFetchingTotpDevices: false };

        case "CREATE_TOTP_DEVICE":
            return { ...state, isCreatingTotpDevice: true };
        case "CREATE_TOTP_DEVICE_SUCCESS": {
            const devices = state.totpDevices || [];
            devices.push(action.device);
            return { ...state, isCreatingTotpDevice: false, totpDevices: devices };
        }
        case "CREATE_TOTP_DEVICE_FAILURE":
            return { ...state, isCreatingTotpDevice: false };

        case "UPDATE_TOTP_DEVICE":
            return { ...state, isUpdatingTotpDevice: true };
        case "UPDATE_TOTP_DEVICE_SUCCESS": {
            const devices = state.totpDevices || [];
            devices[devices.findIndex((d) => d.id === action.id)] = action.device;
            return { ...state, isUpdatingTotpDevice: false, totpDevices: devices };
        }
        case "UPDATE_TOTP_DEVICE_FAILURE":
            return { ...state, isUpdatingTotpDevice: false };

        case "DELETE_TOTP_DEVICE":
            return { ...state, isDeletingTotpDevice: true };
        case "DELETE_TOTP_DEVICE_SUCCESS": {
            const devices = state.totpDevices || [];
            devices.splice(
                devices.findIndex((d) => d.id === action.id),
                1
            );
            return { ...state, isDeletingTotpDevice: false, totpDevices: devices };
        }
        case "DELETE_TOTP_DEVICE_FAILURE":
            return { ...state, isDeletingTotpDevice: false };

        case "FETCH_SMS_DEVICES":
            return { ...state, isFetchingSmsDevices: true };
        case "FETCH_SMS_DEVICES_SUCCESS":
            return { ...state, isFetchingSmsDevices: false, smsDevices: action.devices };
        case "FETCH_SMS_DEVICES_FAILURE":
            return { ...state, isFetchingSmsDevices: false };

        case "CREATE_SMS_DEVICE":
            return { ...state, isCreatingSmsDevice: true };
        case "CREATE_SMS_DEVICE_SUCCESS": {
            const devices = state.smsDevices || [];
            devices.push(action.device);
            return { ...state, isCreatingSmsDevice: false, smsDevices: devices };
        }
        case "CREATE_SMS_DEVICE_FAILURE":
            return { ...state, isCreatingSmsDevice: false };

        case "UPDATE_SMS_DEVICE":
            return { ...state, isUpdatingSmsDevice: true };
        case "UPDATE_SMS_DEVICE_SUCCESS": {
            const devices = state.smsDevices || [];
            devices[devices.findIndex((d) => d.id === action.id)] = action.device;
            return { ...state, isUpdatingSmsDevice: false, smsDevices: devices };
        }
        case "UPDATE_SMS_DEVICE_FAILURE":
            return { ...state, isUpdatingSmsDevice: false };

        case "DELETE_SMS_DEVICE":
            return { ...state, isDeletingSmsDevice: true };
        case "DELETE_SMS_DEVICE_SUCCESS": {
            const devices = state.smsDevices || [];
            devices.splice(
                devices.findIndex((d) => d.id === action.id),
                1
            );
            return { ...state, isDeletingSmsDevice: false, smsDevices: devices };
        }
        case "DELETE_SMS_DEVICE_FAILURE":
            return { ...state, isDeletingSmsDevice: false };

        case "SEND_SMS":
            return { ...state, isSendingSms: true };
        case "SEND_SMS_SUCCESS":
            return { ...state, isSendingSms: false };
        case "SEND_SMS_FAILURE":
            return { ...state, isSendingSms: false };

        case "VERIFY_OTP_CODE":
            return { ...state, isVerifyingOtpCode: true };
        case "VERIFY_OTP_CODE_SUCCESS":
            if (!state.isLoggedIn && action.sessionStore) {
                setAuthStorage(action.sessionStore);
            }
            return { ...state, isLoggedIn: true, isVerifyingOtpCode: false };
        case "VERIFY_OTP_CODE_FAILURE":
            return { ...state, isVerifyingOtpCode: false };

        case "LOGOUT":
            return { ...state, isLoggingOut: true };
        case "LOGOUT_SUCCESS":
            return { ...state, isLoggingOut: false, isLoggedIn: false };
        case "LOGOUT_FAILURE":
            return { ...state, isLoggingOut: false };

        case "REFRESH_CREDENTIALS":
            return { ...state, isRefreshingCredentials: true };
        case "REFRESH_CREDENTIALS_SUCCESS":
            setAuthStorage(action.sessionStore);
            return { ...state, isRefreshingCredentials: false };
        case "REFRESH_CREDENTIALS_FAILURE":
            return { ...state, isRefreshingCredentials: false };

        case "CLEAR_CREDENTIALS":
            removeAuthStorage();
            invalidateLocalCookie("csrftoken");
            return { ...initialState, isLoggedIn: false };

        default:
            return { ...state };
    }
}

export default reducer;
