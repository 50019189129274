import React, { useCallback, useMemo } from "react";
import reducer, {
    IAction,
    ICommunityStaff,
    ICommunityStaffInvitation,
    ICommunityState,
    IEditCommunityStaff,
    ICostCalculationRun,
    INewCommunityStaff,
    initialState,
    ICreateCostCalculationRun,
    IRepresentative,
    IRepresentativeInvitation,
} from "reducers/community";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";
import { TCommunity, TProductCommunity } from "utils/ecommerseTypes";
import qs from "qs";
import { NotificationContext } from "./notification";
import { apiGet, apiPatch, apiPost, apiPostGetRawBlob, apiPut } from "fetchApi";
import { exhaustive } from "exhaustive";

export const CommunityContext = React.createContext({
    ...initialState,
});

export const CommunityProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { ...notification } = React.useContext(NotificationContext);
    const [currentState, dispatch] = React.useReducer<React.Reducer<ICommunityState, IAction>>(reducer, initialState);

    const fetchCommunityStaff = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<ICommunityStaff>> => {
            dispatch({ type: "FETCH_COMMUNITY_STAFF" });
            const returnData = await apiGet<TPaginationActionData<ICommunityStaff>>("/communities/staff-users/", { params });

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_COMMUNITY_STAFF_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_COMMUNITY_STAFF_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchCommunityStaffInvitations = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<ICommunityStaffInvitation>> => {
            dispatch({ type: "FETCH_COMMUNITY_STAFF_INVITATIONS" });
            const returnData = await apiGet<TPaginationActionData<ICommunityStaffInvitation>>("/communities/staff-invitations/", { params });

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_COMMUNITY_STAFF_INVITATIONS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_COMMUNITY_STAFF_INVITATIONS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchCommunity = useCallback(async (uuid: string): Promise<TCommunity> => {
        dispatch({ type: "FETCH_COMMUNITY" });
        const returnData = await apiGet<TCommunity>(`/communities/${uuid}/`);

        return exhaustive(returnData, "responseType", {
            Success: (it) => {
                dispatch({
                    type: "FETCH_COMMUNITY_SUCCESS",
                });
                return it.data;
            },
            Error: () => {
                dispatch({ type: "FETCH_COMMUNITY_FAILURE" });
                return {} as TCommunity;
            },
        });
    }, []);

    const fetchCommunities = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<TCommunity>> => {
            dispatch({ type: "FETCH_COMMUNITIES" });
            const returnData = await apiGet<TPaginationActionData<TCommunity>>("/communities/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_COMMUNITIES_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_COMMUNITIES_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchProductCommunities = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<TProductCommunity>> => {
            dispatch({ type: "FETCH_PRODUCT_COMMUNITIES" });
            const returnData = await apiGet<TPaginationActionData<TProductCommunity>>("/communities/product-communities/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_PRODUCT_COMMUNITIES_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_PRODUCT_COMMUNITIES_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchCostCalculationRuns = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<ICostCalculationRun>> => {
            dispatch({ type: "FETCH_COST_CALCULATIONS" });
            const returnData = await apiGet<TPaginationActionData<ICostCalculationRun>>("/billings/cost-calculations/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_COST_CALCULATIONS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_COST_CALCULATIONS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const addCommunityStaff = useCallback(async (communityStaff: INewCommunityStaff): Promise<boolean> => {
        dispatch({ type: "ADD_COMMUNITY_STAFF" });
        const data = {
            communities: communityStaff.communities,
            email: communityStaff.email,
            first_name: communityStaff.firstName,
            last_name: communityStaff.lastName,
            main_community: communityStaff.mainCommunity,
            community_staff_role: communityStaff.communityStaffRole,
            phone: communityStaff.phone,
        };
        const response = await apiPost<object>("/communities/staff-invitations/", data, {
            paramsSerializer: (obj: typeof communityStaff) => qs.stringify(obj, { encode: false }),
        });
        return exhaustive(response, "responseType", {
            Success: () => {
                dispatch({ type: "ADD_COMMUNITY_STAFF_SUCCESS" });
                return true;
            },
            Error: () => {
                dispatch({ type: "ADD_COMMUNITY_STAFF_FAILURE" });
                return false;
            },
        });
    }, []);

    const editCommunityStaff = useCallback(async (userId: number, communityStaff: IEditCommunityStaff): Promise<boolean> => {
        dispatch({ type: "EDIT_COMMUNITY_STAFF" });
        const data = {
            communities: communityStaff.communities,
            main_community: communityStaff.mainCommunity,
            role: communityStaff.role,
            community_staff_role: communityStaff.communityStaffRole
        };
        const response = await apiPut<object>(`/communities/staff-users/${userId}/`, data, {
            paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
        });
        return exhaustive(response, "responseType", {
            Success: () => {
                dispatch({ type: "EDIT_COMMUNITY_STAFF_SUCCESS" });
                return true;
            },
            Error: () => {
                dispatch({ type: "EDIT_COMMUNITY_STAFF_FAILURE" });
                return false;
            },
        });
    }, []);

    const deactivateCommunityStaff = useCallback(async (userId: number): Promise<void> => {
        dispatch({ type: "DEACTIVATE_COMMUNITY_STAFF" });
        const response = await apiPatch<object>(`/communities/staff-users/${userId}/deactivate/`, {});
        return exhaustive(response, "responseType", {
            Success: () => {
                dispatch({ type: "DEACTIVATE_COMMUNITY_STAFF_SUCCESS" });
            },
            Error: () => {
                dispatch({ type: "DEACTIVATE_COMMUNITY_STAFF_FAILURE" });
            },
        });
    }, []);

    const addCostCalculation = useCallback(
        async (data: ICreateCostCalculationRun): Promise<Blob | null> => {
            dispatch({ type: "ADD_COST_CALCULATION" });
            const response = await apiPostGetRawBlob<ICreateCostCalculationRun>("/billings/cost-calculations/", data, {
                paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
            });
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "ADD_COST_CALCULATION_SUCCESS" });
                    return it.data;
                },
                Error: (error) => {
                    dispatch({ type: "ADD_COST_CALCULATION_FAILURE" });
                    notification.enqueNotification("error_addCostCalculation", error);
                    return null;
                },
            });
        },
        [notification]
    );

    const fetchRepresentatives = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IRepresentative>> => {
            dispatch({ type: "FETCH_REPRESENTATIVE" });
            const returnData = await apiGet<TPaginationActionData<IRepresentative>>("/communities/representatives/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_REPRESENTATIVE_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_REPRESENTATIVE_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchRepresentativeInvitations = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IRepresentativeInvitation>> => {
            dispatch({ type: "FETCH_REPRESENTATIVE_INVITATION" });
            const returnData = await apiGet<TPaginationActionData<IRepresentativeInvitation>>("/communities/representative-invitations/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_REPRESENTATIVE_INVITATION_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_REPRESENTATIVE_INVITATION_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    React.useEffect(() => {
        if (!initialState.communityStaffPagination.isInitialized()) {
            initialState.communityStaffPagination.initialize(fetchCommunityStaff);
        }
        if (!initialState.communityStaffInvitationPagination.isInitialized()) {
            initialState.communityStaffInvitationPagination.initialize(fetchCommunityStaffInvitations);
        }
        if (!initialState.communitiesPagination.isInitialized()) {
            initialState.communitiesPagination.initialize(fetchCommunities);
        }

        if (!initialState.productCommunitiesPagination.isInitialized()) {
            initialState.productCommunitiesPagination.initialize(fetchProductCommunities);
        }

        if (!initialState.costCalculationPagination.isInitialized()) {
            initialState.costCalculationPagination.initialize(fetchCostCalculationRuns);
        }
        if (!initialState.representativePagination.isInitialized()) {
            initialState.representativePagination.initialize(fetchRepresentatives);
        }
        if (!initialState.representativeInvitationPagination.isInitialized()) {
            initialState.representativeInvitationPagination.initialize(fetchRepresentativeInvitations);
        }
    }, [
        fetchCommunityStaff,
        fetchCommunityStaffInvitations,
        fetchCommunities,
        fetchProductCommunities,
        fetchCostCalculationRuns,
        fetchRepresentatives,
        fetchRepresentativeInvitations,
    ]);

    const value = useMemo(() => {
        return {
            ...currentState,
            addCommunityStaff,
            editCommunityStaff,
            deactivateCommunityStaff,
            addCostCalculation,
            fetchCommunity,
            fetchProductCommunities,
        };
    }, [
        currentState,
        addCommunityStaff,
        editCommunityStaff,
        deactivateCommunityStaff,
        addCostCalculation,
        fetchCommunity,
        fetchProductCommunities,
    ]);

    return <CommunityContext.Provider value={value}>{children}</CommunityContext.Provider>;
};

export default CommunityContext;
