import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import reducer, {
    IMemberships,
    IAction,
    initialState,
    IMembership,
    TMembershipId,
    IContactPerson,
    ITermsAndService,
    ICountryCode,
    IAlternativeInvoiceRecipient,
} from "reducers/memberships";
import { NotificationContext } from "contexts/notification";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";
import { IInvitation } from "reducers/leases";
import { fetchMembershipContactsAdapter } from "adapters/membershipAdapter";
import { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";
import { exhaustive } from "exhaustive";
import { apiDelete, apiGet, apiGetRawBlob, apiPatch, apiPatchGet, apiPostGet } from "fetchApi";

export const MembershipsContext = createContext<IMemberships>({
    ...initialState,
});

export const MembershipsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IMemberships, IAction>>(reducer, initialState);

    const setMembership = useCallback((membership: IMembership, isSingleMembership = false): void => {
        dispatch({
            type: "SET_MEMBERSHIP",
            membership: membership,
        });
        dispatch({
            type: "SET_SINGLE_MEMBERSHIP",
            isSingle: isSingleMembership,
        });
    }, []);

    const fetchMembership = useCallback(
        async (membershipId: TMembershipId): Promise<IMembership> => {
            dispatch({ type: "FETCH_MEMBERSHIP" });
            const returnData = await apiGet<IMembership>(`/memberships/${membershipId}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_MEMBERSHIP_SUCCESS",
                        membership: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchMembership", error);
                    dispatch({ type: "FETCH_MEMBERSHIP_FAILURE" });
                    return {} as IMembership;
                },
            });
        },
        [notification]
    );

    const fetchMemberships = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IMembership>> => {
            dispatch({ type: "FETCH_MEMBERSHIPS" });
            const result = await apiGet<TPaginationActionData<IMembership>>("/memberships/", {
                params,
            });
            return exhaustive(result, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_MEMBERSHIPS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    notification.enqueNotification("error_fetchMemberships");
                    dispatch({ type: "FETCH_MEMBERSHIPS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        [notification]
    );

    const fetchContactPersons = useCallback(
        async (
            membershipId: TMembershipId,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IContactPerson>> => {
            dispatch({ type: "FETCH_CONTACT_PERSONS" });
            const response = await fetchMembershipContactsAdapter(membershipId, params);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_CONTACT_PERSONS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchContactPersons", error);
                    dispatch({ type: "FETCH_CONTACT_PERSONS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        [notification]
    );

    const fetchContactPersonsInvitations = useCallback(
        async (membershipId: TMembershipId): Promise<TPaginationActionData<IInvitation>> => {
            dispatch({ type: "FETCH_INVITATIONS" });
            const response = await apiGet<TPaginationActionData<IInvitation>>(`/memberships/${membershipId}/contact-persons/invitations/`);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_INVITATIONS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchContactPersons", error);
                    dispatch({ type: "FETCH_INVITATIONS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        [notification]
    );

    const addContactPerson = useCallback(
        async (membershipId: TMembershipId, newContact: INewContactObject): Promise<boolean> => {
            interface AddContactPerson {
                email: string,
                first_name: string,
                last_name: string,
                phone_nr: string,
                role_id?: number,
            }
            dispatch({ type: "ADD_CONTACT_PERSON" });
            const data: AddContactPerson = {
                email: newContact.email,
                phone_nr: newContact.phone,
                first_name: newContact.firstName,
                last_name: newContact.lastName,
                role_id: newContact.roleId,
            };
            const response = await apiPostGet<IContactPerson, AddContactPerson>(`/memberships/${membershipId}/contact-persons/`, data);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "ADD_CONTACT_PERSON_SUCCESS", newContactPerson: it.data });
                    notification.enqueNotification("success_addContactPerson");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_addContactPerson", error);
                    dispatch({ type: "ADD_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const editContactPerson = useCallback(
        async (
            membershipId: TMembershipId,
            uuid: string,
            firstName: string,
            lastName: string,
            phone: string,
            role?: number
        ): Promise<boolean> => {
            dispatch({ type: "EDIT_CONTACT_PERSON" });
            interface UpdateContactPerson {
                first_name: string,
                last_name: string,
                phone_nr: string,
                role?: number,
            }
            const data = {
                first_name: firstName,
                last_name: lastName,
                phone_nr: phone,
                role: role,
            };
            const response = await apiPatch<UpdateContactPerson>(`/memberships/${membershipId}/contact-persons/${uuid}/`, data);
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "EDIT_CONTACT_PERSON_SUCCESS" });
                    notification.enqueNotification("success_editContactPerson");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_editContactPerson", error);
                    dispatch({ type: "EDIT_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const removeContactPerson = useCallback(
        async (membershipId: TMembershipId, uuid: string): Promise<boolean> => {
            dispatch({ type: "REMOVE_CONTACT_PERSON" });
            const response = await apiDelete(`/memberships/${membershipId}/contact-persons/${uuid}/`);
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "REMOVE_CONTACT_PERSON_SUCCESS" });
                    notification.enqueNotification("success_removeContactPerson");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_removeContactPerson", error);
                    dispatch({ type: "REMOVE_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const removeInvitation = useCallback(
        async (membershipId: TMembershipId, uuid: string): Promise<boolean> => {
            dispatch({ type: "REMOVE_CONTACT_PERSON" });
            const response = await apiDelete(`/memberships/${membershipId}/contact-persons/invitations/${uuid}/`);
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "REMOVE_CONTACT_PERSON_SUCCESS" });
                    notification.enqueNotification("success_removeInvitation");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_removeInvitation", error);
                    dispatch({ type: "REMOVE_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const clearStorage = useCallback((): void => {
        dispatch({ type: "CLEAR_STORAGE" });
    }, []);

    const getEmailsCSV = useCallback(async (): Promise<boolean> => {
        dispatch({ type: "FETCH_EMAILS_CSV" });
        const returnData = await apiGetRawBlob("/contact-persons/export/");
        return exhaustive(returnData, "responseType", {
            Success: (it) => {
                const url = window.URL.createObjectURL(it.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "emails.csv");
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                dispatch({ type: "FETCH_EMAILS_CSV_SUCCESS" });
                return true;
            },
            Error: (error) => {
                notification.enqueNotification("error_removeInvitation", error);
                dispatch({ type: "FETCH_EMAILS_CSV_FAILURE" });
                return false;
            },
        });
    }, [notification]);

    const updateMembership = useCallback(
        async (membershipId: TMembershipId, data: Record<string, unknown>): Promise<IMembership> => {
            dispatch({ type: "UPDATE_MEMBERSHIP" });
            const returnData = await apiPatchGet<IMembership, Record<string, unknown>>(`/memberships/${membershipId}/`, data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_MEMBERSHIP_SUCCESS", data: it.data });
                    notification.enqueNotification("success_updateMembership");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateMembership", error);
                    dispatch({ type: "UPDATE_MEMBERSHIP_FAILURE" });
                    return {} as IMembership;
                },
            });
        },
        [notification]
    );

    const terminateMembership = useCallback(
        async (membershipId: TMembershipId): Promise<IMembership> => {
            dispatch({ type: "TERMINATE_MEMBERSHIP" });
            const returnData = await apiPatchGet<IMembership, undefined>(`/memberships/${membershipId}/inactivate/`, undefined);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "TERMINATE_MEMBERSHIP_SUCCESS", data: it.data });
                    notification.enqueNotification("success_terminateMembership");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_terminateMembership", error);
                    dispatch({ type: "TERMINATE_MEMBERSHIP_FAILURE" });
                    return {} as IMembership;
                },
            });
        },
        [notification]
    );

    const fetchTac = useCallback(
        async (params?: Record<string, unknown>): Promise<ITermsAndService[]> => {
            dispatch({ type: "FETCH_TAC" });
            const returnData = await apiGet<TPaginationActionData<ITermsAndService>>("/general_terms_and_conditions/", { params: params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "FETCH_TAC_SUCCESS", data: it.data.results });
                    return it.data.results;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchTac", error);
                    dispatch({ type: "FETCH_TAC_FAILURE" });
                    return [];
                },
            });
        },
        [notification]
    );

    const fetchCountries = useCallback(async (): Promise<ICountryCode[]> => {
        dispatch({ type: "FETCH_COUNTRY_CODES" });
        const returnData = await apiGet<ICountryCode[]>("/organization/country-codes/");
        return exhaustive(returnData, "responseType", {
            Success: (it) => {
                dispatch({ type: "FETCH_COUNTRY_CODES_SUCCESS", countries: it.data });
                return it.data;
            },
            Error: (error) => {
                notification.enqueNotification("error_fetchCountryCodes", error);
                dispatch({ type: "FETCH_COUNTRY_CODES_FAILURE" });
                return [];
            },
        });
    }, [notification]);

    const fetchAlternativeRecipient = useCallback(
        async (recipientUuid: string): Promise<IAlternativeInvoiceRecipient> => {
            dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENT" });
            const returnData = await apiGet<IAlternativeInvoiceRecipient>(`/memberships/alternative-invoice-recipients/${recipientUuid}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENT_SUCCESS", });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchAlternativeRecipient", error);
                    dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENT_FAILURE" });
                    return {} as IAlternativeInvoiceRecipient;
                },
            });
        },
        [notification]
    );

    const fetchAlternativeRecipients = useCallback(
        async (params: Record<string, unknown>): Promise<TPaginationActionData<IAlternativeInvoiceRecipient>> => {
            dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENTS" });
            const url = "/memberships/alternative-invoice-recipients/";
            const returnData = await apiGet<TPaginationActionData<IAlternativeInvoiceRecipient>>(url, { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENTS_SUCCESS", });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchAlternativeRecipients", error);
                    dispatch({ type: "FETCH_ALTERNATIVE_RECIPIENTS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        [notification]
    );

    const updateAlternativeRecipient = useCallback(
        async (recipientUuid: string, data: Record<string, unknown>): Promise<IAlternativeInvoiceRecipient> => {
            dispatch({ type: "UPDATE_ALTERNATIVE_RECIPIENT" });
            const url = `/memberships/alternative-invoice-recipients/${recipientUuid}/`;
            const returnData = await apiPatchGet<IAlternativeInvoiceRecipient, Record<string, unknown>>(url, data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_ALTERNATIVE_RECIPIENT_SUCCESS" });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateAlternativeRecipient", error);
                    dispatch({ type: "UPDATE_ALTERNATIVE_RECIPIENT_FAILURE" });
                    return {} as IAlternativeInvoiceRecipient;
                },
            });
        },
        [notification]
    );

    const createAlternativeRecipient = useCallback(
        async (data: Record<string, unknown>): Promise<IAlternativeInvoiceRecipient> => {
            dispatch({ type: "CREATE_ALTERNATIVE_RECIPIENT" });
            const url = "/memberships/alternative-invoice-recipients/";
            const returnData = await apiPostGet<IAlternativeInvoiceRecipient, Record<string, unknown>>(url, data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "CREATE_ALTERNATIVE_RECIPIENT_SUCCESS" });
                    notification.enqueNotification("success_createAlternativeRecipient");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateAlternativeRecipient", error);
                    dispatch({ type: "CREATE_ALTERNATIVE_RECIPIENT_FAILURE" });
                    return {} as IAlternativeInvoiceRecipient;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchMembership,
            fetchMemberships,
            fetchContactPersons,
            fetchContactPersonsInvitations,
            addContactPerson,
            editContactPerson,
            removeContactPerson,
            removeInvitation,
            clearStorage,
            getEmailsCSV,
            updateMembership,
            terminateMembership,
            fetchTac,
            setMembership,
            fetchCountries,
            fetchAlternativeRecipient,
            fetchAlternativeRecipients,
            updateAlternativeRecipient,
            createAlternativeRecipient,
        };
    }, [
        currentState,
        fetchMembership,
        fetchMemberships,
        fetchContactPersons,
        fetchContactPersonsInvitations,
        addContactPerson,
        editContactPerson,
        removeContactPerson,
        removeInvitation,
        clearStorage,
        getEmailsCSV,
        updateMembership,
        terminateMembership,
        fetchTac,
        setMembership,
        fetchCountries,
        fetchAlternativeRecipient,
        fetchAlternativeRecipients,
        updateAlternativeRecipient,
        createAlternativeRecipient,
    ]);

    return <MembershipsContext.Provider value={value}>{children}</MembershipsContext.Provider>;
};
