import { apiFormDataPostGet, apiGet, FetchResponse, NoDataResponse } from "fetchApi";
import { ILease } from "reducers/leases";
import { IContactPerson } from "reducers/memberships";
import { TPaginationActionData } from "utils/paginationStore";

export const fetchLeasesContactsAdapter = async (
    lease_uuid: string,
    params?: Record<string, unknown>
): Promise<FetchResponse<TPaginationActionData<IContactPerson>>> => {
    return apiGet(`/vk_data/leases/${lease_uuid}/contact-persons/`, { params });
};

export const sendLeaseQuestion = async (data: FormData): Promise<NoDataResponse> => {
    return apiFormDataPostGet<FormData>("/vk_data/leases/mail-questions/", data, {});
};

export const fetchLeases = async (
    params?: Record<string, unknown>
): Promise<FetchResponse<TPaginationActionData<ILease>>> => {
    return apiGet<TPaginationActionData<ILease>>("/vk_data/leases/", { params });
};
