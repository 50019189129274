import { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";
import { TCommunity } from "utils/ecommerseTypes";
import PaginationStore, { TPaginationActionData } from "utils/paginationStore";
import { IInvitation } from "./leases";
import { IRole } from "./roleManagement";

export interface IOrganization {
    id: number;
    content_type_id: number;
    reference: string;
    org_nr: string;
    company_name: string; // eg "Malvacom Sweden AB"
    name: string; // eg "Malvacom"
    created: string;
    updated: string;
    country_code?: ICountryCode;
}

export interface ICountryCode {
    uuid: string;
    code: string;
    verbose_en: string;
    verbose_sv: string;
}

export interface IMembership {
    id: number;
    organization: IOrganization;
    created: string;
    updated: string;
    agreement_type: TAgreementType;
    agreement_nr: string;
    agreement_document_nr: string;
    agreement_document_file: string;
    order_id: number;
    branch_code: string;
    subject_to_vat: boolean;
    start_date: string | null;
    end_date: string | null;
    content_type_id: number;
    role_display_name: string;
    postal_address1: string;
    postal_address2: string;
    postal_city: string;
    postal_postcode: string;
    selected_invoicing_method: string;
    pdf_address: string;
    peppol_address: string;
    subscriptions_count: number;
    offices_count: number;
    invitations_count: number;
    contact_persons_count: number;
    user_invoice_reference: string;
    default_community?: TCommunity;
    free_form_text: string;
    alternative_invoice_recipient?: string;
    inactivation_date?: string;
    is_possible_to_terminate?: boolean;
    is_archived: boolean;
    is_active: boolean;
    contract_contact_name: string;
    contract_contact_phone: string;
    contract_contact_email: string;
}

export type TAgreementType = "arena_member" | "external_member" | "conference_member";

export interface IAlternativeInvoiceRecipient {
    uuid: string;
    created: string;
    postal_address1: string;
    postal_address2: string;
    postal_city: string;
    postal_postcode: string;
    user_invoice_reference: string;
    selected_invoicing_method: string;
    pdf_address: string;
    peppol_address: string;
    organization: IOrganization;
}

export interface IContactPerson {
    uuid: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    id: number;
    role: IRole;
    can_manage: boolean;
}

export interface ITermsAndService {
    id: number;
    name: string;
    file: string;
}

export interface IAvailability {
    total_office_slots: number;
    total_subscriptions: number;
}

export type TMembershipId = number;

export interface IMemberships {
    membership?: IMembership;
    pagination: PaginationStore<IMembership>;
    contactPersons: PaginationStore<IContactPerson>;
    pendingContactPersons: PaginationStore<IInvitation>;
    tacDocuments: ITermsAndService[];
    countries: ICountryCode[];
    orgHasSingleMembership: boolean;
    isFetchingMembership: boolean;
    isFetchingMemberships: boolean;
    isFetchingContactPersons: boolean;
    isFetchingInvitations: boolean;
    isAddingContactPerson: boolean;
    isEditingContactPerson: boolean;
    isRemovingContactPerson: boolean;
    isFetchingEmailsCSV: boolean;
    isUpdatingMembership: boolean;
    isTerminatingMembership: boolean;
    isFetchingTac: boolean;
    isFetchingCountries: boolean;
    isFetchingAlternativeRecipient: boolean;
    isFetchingAlternativeRecipients: boolean;
    isUpdatingAlternativeRecipient: boolean;
    isCreatingAlternativeRecipient: boolean;
    fetchMembership: (membershipId: TMembershipId) => Promise<IMembership>;
    fetchMemberships: (params: Record<string, unknown>) => Promise<TPaginationActionData<IMembership>>;
    fetchContactPersons: (
        membershipId: TMembershipId,
        params: Record<string, unknown>
    ) => Promise<TPaginationActionData<IContactPerson>>;
    fetchContactPersonsInvitations: (
        membershipId: TMembershipId,
        params: Record<string, unknown>
    ) => Promise<TPaginationActionData<IInvitation>>;
    addContactPerson: (membershipId: TMembershipId, newContact: INewContactObject) => Promise<boolean>;
    editContactPerson: (
        membershipId: TMembershipId,
        uuid: string,
        firstName: string,
        lastName: string,
        phone: string,
        role?: number
    ) => Promise<boolean>;
    removeContactPerson: (membershipId: TMembershipId, uuid: string) => Promise<boolean>;
    removeInvitation: (membershipId: TMembershipId, uuid: string) => Promise<boolean>;
    clearStorage: () => void;
    getEmailsCSV: () => Promise<boolean>;
    updateMembership: (membershipId: TMembershipId, data: Record<string, unknown>) => Promise<IMembership>;
    terminateMembership: (membershipId: TMembershipId) => Promise<IMembership>;
    fetchTac: (params?: Record<string, unknown>) => Promise<ITermsAndService[]>;
    setMembership: (membership: IMembership, isSingleMembership: boolean) => void;
    fetchCountries: () => Promise<ICountryCode[]>;
    fetchAlternativeRecipient: (recipientUuid: string) => Promise<IAlternativeInvoiceRecipient>;
    fetchAlternativeRecipients: (
        params: Record<string, unknown>
    ) => Promise<TPaginationActionData<IAlternativeInvoiceRecipient>>;
    updateAlternativeRecipient: (
        recipientUuid: string,
        data: Record<string, unknown>
    ) => Promise<IAlternativeInvoiceRecipient>;
    createAlternativeRecipient: (data: Record<string, unknown>) => Promise<IAlternativeInvoiceRecipient>;
}

export interface IMembershipsQueryParams {
    is_archived?: boolean;
    is_active?: boolean;
    end_date__gte?: string;
    end_date__lte?: string;
}

export const initialState: IMemberships = {
    pagination: new PaginationStore<IMembership>({ search: "" }),
    contactPersons: new PaginationStore<IContactPerson>(),
    pendingContactPersons: new PaginationStore<IInvitation>(),
    tacDocuments: [],
    countries: [],
    isFetchingMembership: false,
    isFetchingMemberships: false,
    isFetchingContactPersons: false,
    isFetchingInvitations: false,
    isAddingContactPerson: false,
    isEditingContactPerson: false,
    isRemovingContactPerson: false,
    isFetchingEmailsCSV: false,
    isUpdatingMembership: false,
    isTerminatingMembership: false,
    isFetchingTac: false,
    orgHasSingleMembership: false,
    isFetchingCountries: false,
    isFetchingAlternativeRecipient: false,
    isFetchingAlternativeRecipients: false,
    isUpdatingAlternativeRecipient: false,
    isCreatingAlternativeRecipient: false,
    fetchMembership: async () => ({} as IMembership),
    fetchMemberships: async () => ({} as TPaginationActionData<IMembership>),
    fetchContactPersons: async () => ({} as TPaginationActionData<IContactPerson>),
    fetchContactPersonsInvitations: async () => ({} as TPaginationActionData<IInvitation>),
    addContactPerson: async () => false,
    editContactPerson: async () => false,
    removeContactPerson: async () => false,
    removeInvitation: async () => false,
    clearStorage: async () => { },
    getEmailsCSV: async () => false,
    updateMembership: async () => ({} as IMembership),
    terminateMembership: async () => ({} as IMembership),
    fetchTac: async () => ({} as ITermsAndService[]),
    setMembership: async () => { },
    fetchCountries: async () => ({} as ICountryCode[]),
    fetchAlternativeRecipient: async () => ({} as IAlternativeInvoiceRecipient),
    fetchAlternativeRecipients: async () => ({} as TPaginationActionData<IAlternativeInvoiceRecipient>),
    updateAlternativeRecipient: async () => ({} as IAlternativeInvoiceRecipient),
    createAlternativeRecipient: async () => ({} as IAlternativeInvoiceRecipient),
};

export type IAction =
    | { type: "SET_MEMBERSHIP"; membership: IMembership }
    | { type: "FETCH_MEMBERSHIP" }
    | { type: "FETCH_MEMBERSHIP_SUCCESS"; membership: IMembership }
    | { type: "FETCH_MEMBERSHIP_FAILURE" }
    | { type: "FETCH_MEMBERSHIPS" }
    | {
        type: "FETCH_MEMBERSHIPS_SUCCESS";
        data: TPaginationActionData<IMembership>;
    }
    | { type: "FETCH_MEMBERSHIPS_FAILURE" }
    | { type: "FETCH_CONTACT_PERSONS" }
    | {
        type: "FETCH_CONTACT_PERSONS_SUCCESS";
        data: TPaginationActionData<IContactPerson>;
    }
    | { type: "FETCH_CONTACT_PERSONS_FAILURE" }
    | { type: "FETCH_INVITATIONS" }
    | {
        type: "FETCH_INVITATIONS_SUCCESS";
        data: TPaginationActionData<IInvitation>;
    }
    | { type: "FETCH_INVITATIONS_FAILURE" }
    | { type: "ADD_CONTACT_PERSON" }
    | { type: "ADD_CONTACT_PERSON_SUCCESS"; newContactPerson: IContactPerson }
    | { type: "ADD_CONTACT_PERSON_FAILURE" }
    | { type: "EDIT_CONTACT_PERSON" }
    | { type: "EDIT_CONTACT_PERSON_SUCCESS" }
    | { type: "EDIT_CONTACT_PERSON_FAILURE" }
    | { type: "REMOVE_CONTACT_PERSON" }
    | { type: "REMOVE_CONTACT_PERSON_SUCCESS" }
    | { type: "REMOVE_CONTACT_PERSON_FAILURE" }
    | { type: "CLEAR_STORAGE" }
    | { type: "FETCH_EMAILS_CSV" }
    | { type: "FETCH_EMAILS_CSV_SUCCESS" }
    | { type: "FETCH_EMAILS_CSV_FAILURE" }
    | { type: "UPDATE_MEMBERSHIP" }
    | { type: "UPDATE_MEMBERSHIP_SUCCESS"; data: IMembership }
    | { type: "UPDATE_MEMBERSHIP_FAILURE" }
    | { type: "TERMINATE_MEMBERSHIP" }
    | { type: "TERMINATE_MEMBERSHIP_SUCCESS"; data: IMembership }
    | { type: "TERMINATE_MEMBERSHIP_FAILURE" }
    | { type: "FETCH_TAC" }
    | { type: "FETCH_TAC_SUCCESS"; data: ITermsAndService[] }
    | { type: "FETCH_TAC_FAILURE" }
    | { type: "FETCH_COUNTRY_CODES" }
    | { type: "FETCH_COUNTRY_CODES_SUCCESS"; countries: ICountryCode[] }
    | { type: "FETCH_COUNTRY_CODES_FAILURE" }
    | { type: "FETCH_ALTERNATIVE_RECIPIENT" }
    | { type: "FETCH_ALTERNATIVE_RECIPIENT_SUCCESS" }
    | { type: "FETCH_ALTERNATIVE_RECIPIENT_FAILURE" }
    | { type: "FETCH_ALTERNATIVE_RECIPIENTS" }
    | { type: "FETCH_ALTERNATIVE_RECIPIENTS_SUCCESS" }
    | { type: "FETCH_ALTERNATIVE_RECIPIENTS_FAILURE" }
    | { type: "UPDATE_ALTERNATIVE_RECIPIENT" }
    | { type: "UPDATE_ALTERNATIVE_RECIPIENT_SUCCESS" }
    | { type: "UPDATE_ALTERNATIVE_RECIPIENT_FAILURE" }
    | { type: "CREATE_ALTERNATIVE_RECIPIENT" }
    | { type: "CREATE_ALTERNATIVE_RECIPIENT_SUCCESS" }
    | { type: "CREATE_ALTERNATIVE_RECIPIENT_FAILURE" }
    | { type: "SET_SINGLE_MEMBERSHIP"; isSingle: boolean };

function reducer(state: IMemberships, action: IAction): IMemberships {
    switch (action.type) {
        case "SET_MEMBERSHIP":
            return { ...state, membership: action.membership };

        case "FETCH_MEMBERSHIP":
            return { ...state, isFetchingMembership: true };
        case "FETCH_MEMBERSHIP_SUCCESS":
            return { ...state, membership: action.membership, isFetchingMembership: false };
        case "FETCH_MEMBERSHIP_FAILURE":
            return { ...state, isFetchingMembership: false, membership: undefined };

        case "FETCH_MEMBERSHIPS":
            return { ...state, isFetchingMemberships: true };
        case "FETCH_MEMBERSHIPS_SUCCESS":
        case "FETCH_MEMBERSHIPS_FAILURE":
            return { ...state, isFetchingMemberships: false };

        case "FETCH_CONTACT_PERSONS":
            return { ...state, isFetchingContactPersons: true };
        case "FETCH_CONTACT_PERSONS_SUCCESS":
        case "FETCH_CONTACT_PERSONS_FAILURE":
            return { ...state, isFetchingContactPersons: false };

        case "FETCH_INVITATIONS":
            return { ...state, isFetchingInvitations: true };
        case "FETCH_INVITATIONS_SUCCESS":
        case "FETCH_INVITATIONS_FAILURE":
            return { ...state, isFetchingInvitations: false };

        case "ADD_CONTACT_PERSON":
            return { ...state, isAddingContactPerson: true };
        case "ADD_CONTACT_PERSON_SUCCESS":
            state.pendingContactPersons.reloadPage();
            state.contactPersons.reloadPage();
            return {
                ...state,
                isAddingContactPerson: false,
            };
        case "ADD_CONTACT_PERSON_FAILURE":
            return { ...state, isAddingContactPerson: false };

        case "EDIT_CONTACT_PERSON":
            return { ...state, isEditingContactPerson: true };
        case "EDIT_CONTACT_PERSON_SUCCESS":
            state.contactPersons.reloadPage();
            return { ...state, isEditingContactPerson: false };
        case "EDIT_CONTACT_PERSON_FAILURE":
            return { ...state, isEditingContactPerson: false };

        case "REMOVE_CONTACT_PERSON":
            return { ...state, isRemovingContactPerson: true };
        case "REMOVE_CONTACT_PERSON_SUCCESS":
            state.contactPersons.reloadPage();
            state.pendingContactPersons.reloadPage();
            return { ...state, isRemovingContactPerson: false };
        case "REMOVE_CONTACT_PERSON_FAILURE":
            return { ...state, isRemovingContactPerson: false };

        case "CLEAR_STORAGE":
            return {
                ...initialState,
                pagination: new PaginationStore<IMembership>({ search: "" }),
            };

        case "FETCH_EMAILS_CSV":
            return { ...state, isFetchingEmailsCSV: true };
        case "FETCH_EMAILS_CSV_SUCCESS":
        case "FETCH_EMAILS_CSV_FAILURE":
            return { ...state, isFetchingEmailsCSV: false };

        case "UPDATE_MEMBERSHIP":
            return { ...state, isUpdatingMembership: true };
        case "UPDATE_MEMBERSHIP_SUCCESS": {
            return { ...state, isUpdatingMembership: false, membership: action.data };
        }
        case "UPDATE_MEMBERSHIP_FAILURE":
            return { ...state, isUpdatingMembership: false };

        case "TERMINATE_MEMBERSHIP":
            return { ...state, isTerminatingMembership: true };
        case "TERMINATE_MEMBERSHIP_SUCCESS": {
            return { ...state, isTerminatingMembership: false, membership: action.data };
        }
        case "TERMINATE_MEMBERSHIP_FAILURE":
            return { ...state, isTerminatingMembership: false };

        case "FETCH_TAC":
            return { ...state, isFetchingTac: true };
        case "FETCH_TAC_SUCCESS":
            return { ...state, isFetchingTac: false, tacDocuments: action.data };
        case "FETCH_TAC_FAILURE":
            return { ...state, isFetchingTac: false };

        case "FETCH_COUNTRY_CODES":
            return { ...state, isFetchingCountries: true };
        case "FETCH_COUNTRY_CODES_SUCCESS":
            return { ...state, isFetchingCountries: false, countries: action.countries };
        case "FETCH_COUNTRY_CODES_FAILURE":
            return { ...state, isFetchingCountries: false };

        case "FETCH_ALTERNATIVE_RECIPIENT":
            return { ...state, isFetchingAlternativeRecipient: true };
        case "FETCH_ALTERNATIVE_RECIPIENT_SUCCESS":
        case "FETCH_ALTERNATIVE_RECIPIENT_FAILURE":
            return { ...state, isFetchingAlternativeRecipient: false };

        case "FETCH_ALTERNATIVE_RECIPIENTS":
            return { ...state, isFetchingAlternativeRecipients: true };
        case "FETCH_ALTERNATIVE_RECIPIENTS_SUCCESS":
        case "FETCH_ALTERNATIVE_RECIPIENTS_FAILURE":
            return { ...state, isFetchingAlternativeRecipients: false };

        case "UPDATE_ALTERNATIVE_RECIPIENT":
            return { ...state, isUpdatingAlternativeRecipient: true };
        case "UPDATE_ALTERNATIVE_RECIPIENT_SUCCESS":
        case "UPDATE_ALTERNATIVE_RECIPIENT_FAILURE":
            return { ...state, isUpdatingAlternativeRecipient: false };

        case "CREATE_ALTERNATIVE_RECIPIENT":
            return { ...state, isCreatingAlternativeRecipient: true };
        case "CREATE_ALTERNATIVE_RECIPIENT_SUCCESS":
        case "CREATE_ALTERNATIVE_RECIPIENT_FAILURE":
            return { ...state, isCreatingAlternativeRecipient: false };

        case "SET_SINGLE_MEMBERSHIP":
            return { ...state, orgHasSingleMembership: action.isSingle };

        default:
            return { ...state };
    }
}

export default reducer;
