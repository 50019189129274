import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import reducer, {
    IRealestates,
    IAction,
    IBuildingImage,
    IBuilding,
    IBuildingsText,
    initialState,
} from "reducers/realestates";
import { NotificationContext } from "contexts/notification";
import { apiFormDataPostGet, apiGet, apiGetMultiplePages } from "fetchApi";
import { exhaustive } from "exhaustive";

export const RealestatesContext = createContext<IRealestates>({
    ...initialState,
});

export const RealestatesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IRealestates, IAction>>(reducer, initialState);

    const fetchBuilding = useCallback(
        async (buildingUuid: string): Promise<IBuilding> => {
            dispatch({ type: "FETCH_BUILDING" });
            const returnData = await apiGet<IBuilding>(`/vk_data/buildings/${buildingUuid}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_BUILDING_SUCCESS",
                        building: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchBuildings", error);
                    dispatch({ type: "FETCH_BUILDING_FAILURE" });
                    return {} as IBuilding;
                },
            });
        },
        [notification]
    );

    const fetchBuildingInfo = useCallback(
        async (buildingUuid: string): Promise<boolean> => {
            dispatch({ type: "FETCH_BUILDING_INFO" });
            const params = {
                building_uuid: buildingUuid,
            };
            const returnData = await apiGetMultiplePages<IBuildingsText>("/vk_data/buildings/texts/", {
                params: params,
            });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_BUILDING_INFO_SUCCESS",
                        buildingInfo: it.data,
                    });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchBuildingInfo", error);
                    dispatch({ type: "FETCH_BUILDING_INFO_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const fetchBuildingImages = useCallback(
        async (buildingUuid: string): Promise<boolean> => {
            dispatch({ type: "FETCH_BUILDING_IMAGES" });
            const params = {
                building_uuid: buildingUuid,
            };
            const returnData = await apiGetMultiplePages<IBuildingImage>("/vk_data/buildings/texts/", {
                params: params,
            });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_BUILDING_IMAGES_SUCCESS",
                        buildingImages: it.data,
                    });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchBuildingImages", error);
                    dispatch({ type: "FETCH_BUILDING_IMAGES_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const submitNewTask = useCallback(
        async (newTask: FormData): Promise<boolean> => {
            dispatch({ type: "SUBMIT_TASK" });
            const response = await apiFormDataPostGet<FormData>("/vk_data/tasks/add/", newTask, {});
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "SUBMIT_TASK_SUCCESS" });
                    notification.enqueNotification("success_createTask");
                    return true;
                },
                Error: () => {
                    notification.enqueNotification("error_submitNewTask");
                    dispatch({ type: "SUBMIT_TASK_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchBuilding,
            fetchBuildingInfo,
            fetchBuildingImages,
            submitNewTask,
        };
    }, [currentState, fetchBuilding, fetchBuildingInfo, fetchBuildingImages, submitNewTask]);

    return <RealestatesContext.Provider value={value}>{children}</RealestatesContext.Provider>;
};

export default RealestatesProvider;
