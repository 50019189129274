import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import reducer, { ILeases, IAction, ILease, ILeaseInvitation, initialState } from "reducers/leases";
import { NotificationContext } from "contexts/notification";
import qs from "qs";
import { INewContactObject } from "components/drawers/contactPersons/addContactPersonBase";
import { apiDelete, apiGet, apiPatch, apiPost } from "fetchApi";
import { exhaustive } from "exhaustive";

export const LeasesContext = createContext<ILeases>({
    ...initialState,
});

export const LeasesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<ILeases, IAction>>(reducer, initialState);

    const fetchLease = useCallback(
        async (leaseUuid: string): Promise<ILease> => {
            dispatch({ type: "FETCH_LEASE" });
            const returnData = await apiGet<ILease>(`/vk_data/leases/${leaseUuid}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_LEASE_SUCCESS",
                        lease: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchLeasesInfo", error);
                    dispatch({ type: "FETCH_LEASE_FAILURE" });
                    return {} as ILease;
                },
            });
        },
        [notification]
    );

    const addLeaseContactPerson = useCallback(
        async (lease_uuid: string, newContact: INewContactObject): Promise<boolean> => {
            dispatch({ type: "ADD_LEASE_CONTACT_PERSON" });
            interface IAddLeaseContactPerson {
                lease: string;
                email: string;
                phone: string;
                first_name: string;
                last_name: string;
                role: number;
            }
            const data = {
                lease: lease_uuid,
                email: newContact.email,
                phone: newContact.phone,
                first_name: newContact.firstName,
                last_name: newContact.lastName,
                role: newContact.roleId,
            };
            const response = await apiPost<IAddLeaseContactPerson>("/vk_data/lease-invitations/", data, {
                paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
            });
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "ADD_LEASE_CONTACT_PERSON_SUCCESS" });
                    notification.enqueNotification("success_addContactPerson");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_addContactPerson", error);
                    dispatch({ type: "ADD_LEASE_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const requestAccessToLease = useCallback(
        async (lease_uuid: string, requested_contact_person_email: string): Promise<boolean> => {
            dispatch({ type: "REQUEST_ACCESS" });
            interface RequestAccessToLeasePostBody {
                lease: string;
                requested_contact_person: string;
            }
            const data = {
                lease: lease_uuid,
                requested_contact_person: requested_contact_person_email,
            };
            const response = await apiPost<RequestAccessToLeasePostBody>("/vk_data/lease-invitations/request/", data, {
                paramsSerializer: (obj: typeof data) => qs.stringify(obj, { encode: false }),
            });
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "REQUEST_ACCESS_SUCCESS" });
                    notification.enqueNotification("success_requestingAccessToLease");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_requestingAccessToLease", error);
                    dispatch({ type: "REQUEST_ACCESS_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const removeContactPersonAccess = useCallback(
        async (lease_uuid: string, id: number): Promise<boolean> => {
            dispatch({ type: "REMOVE_CONTACT_PERSON" });
            const response = await apiDelete(`/vk_data/leases/${lease_uuid}/contact-persons/${id}/`);
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "REMOVE_CONTACT_PERSON_SUCCESS" });
                    notification.enqueNotification("success_removeContactPerson");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_removeContactPerson", error);
                    dispatch({ type: "REMOVE_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const editContactPerson = useCallback(
        async (lease_uuid: string, contact_person_id: number, data: Record<string, unknown>): Promise<boolean> => {
            dispatch({ type: "EDIT_LEASE_CONTACT_PERSON" });
            const response = await apiPatch<Record<string, unknown>>(
                `/vk_data/leases/${lease_uuid}/contact-persons/${contact_person_id}/`,
                data
            );
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "EDIT_LEASE_CONTACT_PERSON_SUCCESS" });
                    notification.enqueNotification("success_editContactPerson");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_editContactPerson", error);
                    dispatch({ type: "EDIT_LEASE_CONTACT_PERSON_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const acceptInvitation = useCallback(async (invitationUuid: string): Promise<boolean> => {
        dispatch({ type: "HANDLE_INVITATION" });
        const response = await apiPost<undefined>(`/vk_data/lease-invitations/${invitationUuid}/accept/`, undefined);
        return exhaustive(response, "responseType", {
            Success: () => {
                dispatch({ type: "HANDLE_INVITATION_SUCCESS" });
                return true;
            },
            Error: () => {
                dispatch({ type: "HANDLE_INVITATION_FAILURE" });
                return false;
            },
        });
    }, []);

    const denyInvitation = useCallback(
        async (invitationUuid: string): Promise<boolean> => {
            dispatch({ type: "HANDLE_INVITATION" });
            const response = await apiPost<undefined>(`/vk_data/lease-invitations/${invitationUuid}/deny/`, undefined);
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "HANDLE_INVITATION_SUCCESS" });
                    notification.enqueNotification("success_denyLeaseInvitation");
                    return true;
                },
                Error: () => {
                    notification.enqueNotification("general_error");
                    dispatch({ type: "HANDLE_INVITATION_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const fetchLeaseInvitation = useCallback(
        async (invitationUuid: string): Promise<ILeaseInvitation> => {
            dispatch({ type: "FETCH_INVITATION" });
            const returnData = await apiGet<ILeaseInvitation>(`/vk_data/lease-invitations/${invitationUuid}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "FETCH_INVITATION_SUCCESS" });
                    return it.data;
                },
                Error: () => {
                    notification.enqueNotification("error_fetchLeaseInvitation");
                    dispatch({ type: "FETCH_INVITATION_FAILURE" });
                    return {} as ILeaseInvitation;
                },
            });
        },
        [notification]
    );

    const deleteInvitation = useCallback(async (invitationUuid: string): Promise<boolean> => {
        dispatch({ type: "HANDLE_INVITATION" });
        const response = await apiDelete(`/vk_data/lease-invitations/${invitationUuid}/`);
        return exhaustive(response, "responseType", {
            Success: () => {
                dispatch({ type: "HANDLE_INVITATION_SUCCESS" });
                return true;
            },
            Error: () => {
                dispatch({ type: "HANDLE_INVITATION_FAILURE" });
                return false;
            },
        });
    }, []);

    const value = useMemo(() => {
        return {
            ...currentState,
            requestAccessToLease,
            addLeaseContactPerson,
            removeContactPersonAccess,
            editContactPerson,
            fetchLease,
            acceptInvitation,
            fetchLeaseInvitation,
            denyInvitation,
            deleteInvitation,
        };
    }, [
        currentState,
        requestAccessToLease,
        addLeaseContactPerson,
        removeContactPersonAccess,
        editContactPerson,
        fetchLease,
        acceptInvitation,
        fetchLeaseInvitation,
        denyInvitation,
        deleteInvitation,
    ]);

    return <LeasesContext.Provider value={value}>{children}</LeasesContext.Provider>;
};

export default LeasesContext;
