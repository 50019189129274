import React, { SyntheticEvent } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { Grid, Link, Typography } from "@mui/material";
import VKButton from "components/vkButton";
import LoginWrapper from "components/loginWrapper";
import { LocalizeText } from "components/localizer";
import { LinkContainer } from "./resetPassword";
import { unsubscribeFromNewsMailList } from "adapters/newsAdapter";
import { NotificationContext } from "contexts/notification";
import { exhaustive } from "exhaustive";
import { NoDataResponse } from "fetchApi";

const UnsubscribeMailListView: React.FC = () => {
    const { encoded_email, token } = useParams();
    const { enqueNotification } = React.useContext(NotificationContext);

    const navigate = useNavigate();

    const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();

        const result = await unsubscribeFromNewsMailList({ encoded_email: encoded_email ?? "", token: token ?? "" });
        exhaustive(result, "responseType", {
            Success: (it: NoDataResponse) => {
                enqueNotification("success_updateNewsViaMailPreferences");
                navigate("/login");
            },
            Error: () => {
                enqueNotification("error_updateNewsViaMailPreferences");
            },
        });
    };

    return (
        <LoginWrapper testid="setPassword-root" title="unsubscribeFromNewsByMailTitle">
            <Typography variant="body2" mt="1em">
                <LocalizeText tag="confirmUnsubscribeFromNews" />
            </Typography>
            <VKButton
                data-testid="resetPassword-submitButton"
                tag="confirm"
                fullWidth
                template="primary"
                sx={{ marginTop: "10px", fontSize: "1rem" }}
                onClick={handleSubmit}
            />
            <LinkContainer container>
                <Grid item>
                    <Link component={RouterLink} to="/login" underline="hover">
                        <LocalizeText tag="goToLoginPage" />
                    </Link>
                </Grid>
            </LinkContainer>
        </LoginWrapper>
    );
};

export default UnsubscribeMailListView;
