import { format } from "date-fns";
import { apiGet, FetchResponse } from "fetchApi";
import { IAvailability, IContactPerson, TMembershipId } from "reducers/memberships";
import { dateFormats } from "utils/formats";
import { TPaginationActionData } from "utils/paginationStore";

export const fetchMembershipContactsAdapter = async (
    membershipId: TMembershipId,
    params: Record<string, unknown>
): Promise<FetchResponse<TPaginationActionData<IContactPerson>>> => {
    return apiGet<TPaginationActionData<IContactPerson>>(`/memberships/${membershipId}/contact-persons/`, { params });
};

export const fetchProductsAvailability = async (
    membershipId: number,
    startDate: Date
): Promise<FetchResponse<IAvailability>> => {
    const params = { start_date: format(startDate, dateFormats.APIDATE) };

    return apiGet<IAvailability>(`/memberships/${membershipId}/availability/`, { params });
};