import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { NotificationContext } from "contexts/notification";
import reducer, { IAction, IMember, IMemberSubscriptionAssociation, IMembers, initialState } from "reducers/members";
import { format } from "date-fns";
import { dateFormats } from "utils/formats";
import { apiDelete, apiGet, apiPatch, apiPatchGet, apiPostGet, apiPut } from "fetchApi";
import { exhaustive } from "exhaustive";

export const MembersContext = createContext<IMembers>({
    ...initialState,
});

export const MembersProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IMembers, IAction>>(reducer, initialState);

    const fetchMember = useCallback(
        async (memberId: string): Promise<IMember> => {
            dispatch({ type: "FETCH_MEMBER" });
            const returnData = await apiGet<IMember>(`/members/${memberId}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_MEMBER_SUCCESS",
                        member: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchMember", error);
                    dispatch({ type: "FETCH_MEMBER_FAILURE" });
                    return {} as IMember;
                },
            });
        },
        [notification]
    );

    const fetchAssociation = useCallback(
        async (associationId: string): Promise<IMemberSubscriptionAssociation> => {
            dispatch({ type: "FETCH_ASSOCIATION" });
            const returnData = await apiGet<IMemberSubscriptionAssociation>(`/members/associations/${associationId}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_ASSOCIATION_SUCCESS",
                        association: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchAssociation", error);
                    dispatch({ type: "FETCH_ASSOCIATION_FAILURE" });
                    return {} as IMemberSubscriptionAssociation;
                },
            });
        },
        [notification]
    );

    const createAssociation = useCallback(
        async (data: Record<string, unknown>): Promise<IMemberSubscriptionAssociation> => {
            dispatch({ type: "CREATE_ASSOCIATION" });
            const returnData = await apiPostGet<IMemberSubscriptionAssociation, Record<string, unknown>>("/members/associations/", data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    notification.enqueNotification("success_createAssociation");
                    dispatch({ type: "CREATE_ASSOCIATION_SUCCESS", association: it.data });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_createAssociation", error);
                    dispatch({ type: "CREATE_ASSOCIATION_FAILURE" });
                    return {} as IMemberSubscriptionAssociation;
                },
            });
        },
        [notification]
    );

    const deactivateAssociation = useCallback(
        async (associationId: string, date: Date): Promise<void> => {
            dispatch({ type: "DEACTIVATE_ASSOCIATION" });
            interface IDeactivateAssociation {
                deactivates: string,
            }
            const data: IDeactivateAssociation = {
                deactivates: format(date, dateFormats.WEBDATE),
            };
            const response = await apiPatch<IDeactivateAssociation>(`/members/associations/${associationId}/deactivate/`, data);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "DEACTIVATE_ASSOCIATION_SUCCESS" });
                },
                Error: (error) => {
                    notification.enqueNotification("error_deactivateAssociation", error);
                    dispatch({ type: "DEACTIVATE_ASSOCIATION_FAILURE" });
                },
            });
        },
        [notification]
    );

    const removeAssociation = useCallback(
        async (associationId: string): Promise<boolean> => {
            dispatch({ type: "REMOVE_ASSOCIATION" });
            const response = await apiDelete(`/members/associations/${associationId}/`);

            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "REMOVE_ASSOCIATION_SUCCESS" });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_removeAssociation", error);
                    dispatch({ type: "REMOVE_ASSOCIATION_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const resendInvitation = useCallback(
        async (associationUuid: string): Promise<void> => {
            dispatch({ type: "RESEND_INVITATION" });
            const response = await apiPut<undefined>(`/members/associations/${associationUuid}/resend/`, undefined);

            return exhaustive(response, "responseType", {
                Success: (it) => {
                    notification.enqueNotification("success_resendInvitation");
                    dispatch({ type: "RESEND_INVITATION_SUCCESS" });
                },
                Error: (error) => {
                    notification.enqueNotification("error_resendInvation", error);
                    dispatch({ type: "RESEND_INVITATION_FAILURE" });
                },
            });
        },
        [notification]
    );

    const updateAssociation = useCallback(
        async (associationId: string, data: Record<string, unknown>): Promise<IMemberSubscriptionAssociation> => {
            dispatch({ type: "UPDATE_ASSOCIATION" });
            const returnData = await apiPatchGet<IMemberSubscriptionAssociation, Record<string, unknown>>(`/members/associations/${associationId}/`, data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_ASSOCIATION_SUCCESS", association: it.data });
                    notification.enqueNotification("success_updateAssociation");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateAssociation", error);
                    dispatch({ type: "UPDATE_ASSOCIATION_FAILURE" });
                    return {} as IMemberSubscriptionAssociation;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchMember,
            fetchAssociation,
            createAssociation,
            deactivateAssociation,
            removeAssociation,
            resendInvitation,
            updateAssociation,
        };
    }, [
        currentState,
        fetchMember,
        fetchAssociation,
        createAssociation,
        deactivateAssociation,
        removeAssociation,
        resendInvitation,
        updateAssociation,
    ]);

    return <MembersContext.Provider value={value}>{children}</MembersContext.Provider>;
};
