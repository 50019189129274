import React from "react";
import { DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from "@mui/material";
import VKButton from "components/vkButton";
import { LocalizeText } from "components/localizer";
import { CenteredLoader } from "components/centeredLoader";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import validator from "utils/validator";
import { ModalContext } from "contexts/modals";
import { useIsLoading } from "utils/hooks/useLoading";
import { sendTaskAction } from "adapters/taskAdapter";
import { NotificationContext } from "contexts/notification";
import { exhaustive } from "exhaustive";

interface ICreateTaskActionProps {
    taskNumber: number;
}

const CreateTaskActionDialog: React.FC<ICreateTaskActionProps> = ({ taskNumber }) => {
    const { popDialog } = React.useContext(ModalContext);
    const { enqueNotification } = React.useContext(NotificationContext);
    const [message, setMessage] = React.useState("");
    const [actionSent, setActionSent] = React.useState(false);
    const [isSubmitingAction, setIsSubmiting] = useIsLoading(false);

    const onSubmit = async (): Promise<void> => {
        setIsSubmiting(true);
        const response = await sendTaskAction(taskNumber, message);
        exhaustive(response, "responseType", {
            Success: (it) => {
                setActionSent(true);
            },
            Error: (error) => {
                enqueNotification("error_submitTaskAction", error);
            },
        });
        setIsSubmiting(false);
    };

    const renderDialogContent = (): JSX.Element => {
        if (isSubmitingAction) {
            return <CenteredLoader size={32} />;
        }
        if (actionSent) {
            return (
                <Grid container justifyContent="center" alignItems="center">
                    <DoneOutlineIcon color="primary" style={{ fontSize: "100px" }} />
                    <LocalizeText tag="taskActionCreatedDescription" />
                </Grid>
            );
        }
        return (
            <TextField
                name="description"
                required
                value={message}
                variant="outlined"
                onChange={(event) => setMessage(event.target.value)}
                multiline
                fullWidth
                minRows={4}
                label={<LocalizeText tag="newActionDescription" />}
                error={!validator.textFieldLength(message, 2500)}
                helperText={
                    <LocalizeText
                        tag="numberOfMaxChars"
                        replace={{
                            number: `${message.length}`,
                            max: "2500",
                        }}
                    />
                }
            />
        );
    };

    return (
        <>
            <DialogTitle>
                <LocalizeText tag={`${actionSent ? "taskActionCreated" : "createTaskAction"}`} />
            </DialogTitle>
            <Divider />
            <DialogContent>{renderDialogContent()}</DialogContent>
            <DialogActions>
                {!actionSent && !isSubmitingAction && (
                    <>
                        <VKButton tag="close" template="cancel" onClick={popDialog} />
                        <VKButton
                            tag="send"
                            template="primary"
                            disabled={isSubmitingAction || message.length < 1}
                            onClick={() => onSubmit()}
                        />
                    </>
                )}
                {actionSent && <VKButton tag="ok" template="primary" onClick={popDialog} />}
            </DialogActions>
        </>
    );
};

export default CreateTaskActionDialog;
