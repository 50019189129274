import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { NotificationContext } from "contexts/notification";
import reducer, {
    IAction,
    IInvoiceEvent,
    IInvoiceEventCreationErrors,
    IInvoiceOrderLine,
    IInvoiceOrder,
    IInvoiceValidationData,
    IInvoices,
    initialState,
    IInvoiceLine,
} from "reducers/invoices";
import { apiDelete, apiFormDataPostGet, apiGet, apiPost, apiPostGet, apiPut } from "fetchApi";
import { exhaustive } from "exhaustive";

export const InvoicesContext = createContext<IInvoices>({
    ...initialState,
});

export const InvoicesProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IInvoices, IAction>>(reducer, initialState);

    const fetchInvoiceEvent = useCallback(
        async (invoiceEventId: string): Promise<IInvoiceEvent> => {
            dispatch({ type: "FETCH_INVOICE_EVENT" });
            const returnData = await apiGet<IInvoiceEvent>(`/invoices/events/${invoiceEventId}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_INVOICE_EVENT_SUCCESS",
                        invoiceEvent: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchInvoiceEvent", error);
                    dispatch({ type: "FETCH_INVOICE_EVENT_FAILURE" });
                    return {} as IInvoiceEvent;
                },
            });
        },
        [notification]
    );

    const fetchInvoiceOrder = useCallback(
        async (invoiceOrderId: string): Promise<IInvoiceOrder> => {
            dispatch({ type: "FETCH_INVOICE_ORDER" });
            const returnData = await apiGet<IInvoiceOrder>(`/invoices/orders/${invoiceOrderId}/`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_INVOICE_ORDER_SUCCESS",
                        invoiceOrder: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchInvoiceOrder", error);
                    dispatch({ type: "FETCH_INVOICE_ORDER_FAILURE" });
                    return {} as IInvoiceOrder;
                },
            });
        },
        [notification]
    );

    const retryInvoiceOrder = useCallback(async (uuid: string): Promise<void> => {
        dispatch({ type: "RETRY_INVOICE_ORDER" });
        const response = await apiPut<undefined>(`/invoices/orders/${uuid}/retry/`, undefined);
        return exhaustive(response, "responseType", {
            Success: (it) => {
                dispatch({
                    type: "RETRY_INVOICE_ORDER_SUCCESS",
                });
            },
            Error: () => {
                dispatch({ type: "RETRY_INVOICE_ORDER_FAILURE" });
            },
        });
    }, []);

    const fetchInvoiceOrderLines = useCallback(
        async (invoiceOrderId: string): Promise<IInvoiceOrderLine[]> => {
            dispatch({ type: "FETCH_INVOICE_ORDER_LINES" });
            const returnData = await apiGet<IInvoiceOrderLine[]>(`/invoices/orders/${invoiceOrderId}/order-lines`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_INVOICE_ORDER_LINES_SUCCESS",
                        invoiceOrderLines: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchInvoiceOrderLines", error);
                    dispatch({ type: "FETCH_INVOICE_ORDER_LINES_FAILURE" });
                    return [] as IInvoiceOrderLine[];
                },
            });
        },
        [notification]
    );

    const fetchInvoiceLines = useCallback(
        async (invoiceId: string): Promise<IInvoiceLine[]> => {
            dispatch({ type: "FETCH_INVOICE_LINES" });
            const returnData = await apiGet<IInvoiceLine[]>(`/invoices/${invoiceId}/lines`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_INVOICE_LINES_SUCCESS",
                        invoiceLines: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchInvoiceLines", error);
                    dispatch({ type: "FETCH_INVOICE_LINES_FAILURE" });
                    return [] as IInvoiceLine[];
                },
            });
        },
        [notification]
    );

    const validateInvoiceEvent = useCallback(async (data: IInvoiceValidationData): Promise<boolean> => {

        dispatch({ type: "VALIDATE_INVOICE_EVENT" });
        const response = await apiPost<IInvoiceValidationData>("/invoices/events/validate/", data);
        return exhaustive(response, "responseType", {
            Success: (it) => {
                dispatch({ type: "VALIDATE_INVOICE_EVENT_SUCCESS" });
                return true;
            },
            Error: () => {
                dispatch({ type: "VALIDATE_INVOICE_EVENT_FAILURE" });
                return false;
            },
        });
    }, []);

    const createInvoiceEvent = useCallback(
        async (formData: FormData): Promise<IInvoiceEvent | IInvoiceEventCreationErrors> => {
            dispatch({ type: "CREATE_INVOICE_EVENT" });
            const returnData = await apiFormDataPostGet<IInvoiceEvent>("/invoices/events/upload/", formData);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    notification.enqueNotification("success_createInvoiceEvent");
                    dispatch({ type: "CREATE_INVOICE_EVENT_SUCCESS", invoiceEvent: it.data });
                    return it.data;
                },
                Error: (error) => {
                    dispatch({ type: "CREATE_INVOICE_EVENT_FAILURE" });
                    return error.details as IInvoiceEventCreationErrors;
                },
            });
        },
        [notification]
    );

    const approveInvoiceEvent = useCallback(
        async (invoiceEventId: string): Promise<string> => {
            dispatch({ type: "APPROVE_INVOICE_EVENT" });
            const returnData = await apiPostGet<string, object>(`/invoices/events/${invoiceEventId}/approve/`, {});
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    notification.enqueNotification("success_approveInvoiceEvent");
                    dispatch({ type: "APPROVE_INVOICE_EVENT_SUCCESS" });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_approveInvoiceEvent", error);
                    dispatch({ type: "APPROVE_INVOICE_EVENT_FAILURE" });
                    return "";
                },
            });
        },
        [notification]
    );

    const deleteInvoiceEvent = useCallback(
        async (invoiceEventId: string): Promise<void> => {
            dispatch({ type: "DELETE_INVOICE_EVENT" });
            const response = await apiDelete(`/invoices/events/${invoiceEventId}/`);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "DELETE_INVOICE_EVENT_SUCCESS" });
                },
                Error: (error) => {
                    notification.enqueNotification("error_deleteInvoiceEvent", error);
                    dispatch({ type: "DELETE_INVOICE_EVENT_FAILURE" });
                },
            });
        },
        [notification]
    );

    const createFromMembership = useCallback(
        async (data: Record<string, unknown>): Promise<IInvoiceOrder> => {
            dispatch({ type: "CREATE_FROM_MEMBERSHIP" });
            const returnData = await apiPostGet<IInvoiceOrder, Record<string, unknown>>("/invoices/orders/from-membership/", data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    notification.enqueNotification("success_createFromMembership");
                    dispatch({ type: "CREATE_FROM_MEMBERSHIP_SUCCESS" });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_createFromMembership", error);
                    dispatch({ type: "CREATE_FROM_MEMBERSHIP_FAILURE" });
                    return {} as IInvoiceOrder;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchInvoiceEvent,
            fetchInvoiceOrder,
            retryInvoiceOrder,
            fetchInvoiceOrderLines,
            fetchInvoiceLines,
            validateInvoiceEvent,
            createInvoiceEvent,
            approveInvoiceEvent,
            deleteInvoiceEvent,
            createFromMembership,
        };
    }, [
        currentState,
        fetchInvoiceEvent,
        fetchInvoiceOrder,
        retryInvoiceOrder,
        fetchInvoiceOrderLines,
        fetchInvoiceLines,
        validateInvoiceEvent,
        createInvoiceEvent,
        approveInvoiceEvent,
        deleteInvoiceEvent,
        createFromMembership,
    ]);

    return <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>;
};
