import Cookies from "js-cookie";
import config from "config";
import qs from "qs";
import { exhaustive } from "exhaustive";

export type FetchResponse<T> = SuccessfullResponse<T> | ErrorResponse

export type NoDataResponse = EmptySuccessfullResponse | ErrorResponse

export interface SuccessfullResponse<T> {
    responseType: "Success";
    data: T;
}

export interface EmptySuccessfullResponse {
    responseType: "Success";
}

export interface ErrorResponse {
    responseType: "Error";
    code?: number;
    details: string;
}

interface RequestOpts {
    data?: object;
    params?: Record<string, any>;
    extra?: any;
    paramsSerializer?: (params: any) => string;
    excludeCredentials?: boolean;
    headers?: Record<string, string>;
}

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type ContentTypeHeader = { type: "DEFAULT" } | { type: "SKIP" } | { type: "REPLACE", contentType: string }

const getHeaders = (method: Method, body: string | undefined | FormData, contentTypeHeader: ContentTypeHeader, opts?: RequestOpts): RequestInit => {
    const csrftoken = Cookies.get("csrftoken");
    let language = localStorage.getItem("lang");
    if (!language) {
        language = navigator.language || "sv";
    }

    const headers = {
        credentials: "include",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Accept-Language": language,
            "X-Csrftoken": csrftoken,
        },
        method: method,
        mode: "cors",
        redirect: "follow",
        referrerPolicy: "origin-when-cross-origin",
        body: body,
        paramsSerializer: qs.stringify,
        ...opts?.extra,
    };

    exhaustive(contentTypeHeader, "type", {
        DEFAULT: () => headers.headers["Content-Type"] = "application/json",
        SKIP: () => {/* Don't add content type to header */ },
        REPLACE: (it) => headers.headers["Content-Type"] = it.contentType,
    });

    return headers;
};

const getFormattedUrl = (url: string, searchParams?: Record<string, any>): string => {
    let formattedUrl = process.env.API_PREFIX ?? "" + url;
    if (searchParams) {
        formattedUrl = formattedUrl + "?" + new URLSearchParams(searchParams);
    }
    return config.apiURL + formattedUrl;
};


const request = async <T>(method: Method, url: string, body: string | undefined | FormData, useRawResponse: boolean, contentTypeHeader: ContentTypeHeader, opts?: RequestOpts): Promise<FetchResponse<T>> => {
    const formattedUrl = getFormattedUrl(url, opts?.params);
    const headers = getHeaders(method, body, contentTypeHeader, opts);
    const response = await fetch(formattedUrl, headers);

    if ("status" in response) {
        if (response.status >= 400) {
            const json = await response.json();
            return {
                responseType: "Error",
                code: response.status,
                details: json.details ?? "Something went wrong"
            };
        }
        if (useRawResponse) {
            return {
                responseType: "Success",
                data: await response.blob() as T
            };
        } else {
            return {
                responseType: "Success",
                data: await tryParseJson<T>(response)
            };
        }
    } else {
        return {
            responseType: "Error",
            details: "Something went wrong and the response  status is missing"
        };
    }
};

const tryParseJson = async <T>(response: Response): Promise<T> => {
    try {
        const parsedJson = await response.json();
        return parsedJson as T;
    } catch (e) {
        return {} as T;
    }
};

export const apiGet = async <T>(url: string, opts?: RequestOpts): Promise<FetchResponse<T>> => {
    return request<T>("GET", url, undefined, false, { type: "DEFAULT" }, opts);
};

export const apiGetRawBlob = async (url: string, opts?: RequestOpts): Promise<FetchResponse<Blob>> => {
    return request<Blob>("GET", url, undefined, true, { type: "DEFAULT" }, opts);
};

export const apiPost = async <R>(url: string, body: R, opts?: RequestOpts, contentTypeHeader?: string): Promise<NoDataResponse> => {
    let contentType: ContentTypeHeader = { type: "DEFAULT" };
    if (contentTypeHeader != null) {
        contentType = { type: "REPLACE", contentType: contentTypeHeader };
    }
    return request("POST", url, JSON.stringify(body), false, contentType, opts);
};

export const apiPostGet = async <T, R>(url: string, body: R, opts?: RequestOpts, contentTypeHeader?: string): Promise<FetchResponse<T>> => {
    let contentType: ContentTypeHeader = { type: "DEFAULT" };
    if (contentTypeHeader != null) {
        contentType = { type: "REPLACE", contentType: contentTypeHeader };
    }
    return request<T>("POST", url, JSON.stringify(body), false, contentType, opts);
};

export const apiFormDataPostGet = async <T>(url: string, body: FormData, opts?: RequestOpts): Promise<FetchResponse<T>> => {
    return request<T>("POST", url, body, false, { type: "SKIP" }, opts);
};

export const apiPostGetRawBlob = async <R>(url: string, body: R, opts?: RequestOpts): Promise<FetchResponse<Blob>> => {
    return request<Blob>("POST", url, JSON.stringify(body), true, { type: "DEFAULT" }, opts);
};

export const apiPut = async <R>(url: string, body: R, opts?: RequestOpts): Promise<NoDataResponse> => {
    return request("PUT", url, JSON.stringify(body), false, { type: "DEFAULT" }, opts);
};

export const apiPatch = async <R>(url: string, body: R, opts?: RequestOpts): Promise<NoDataResponse> => {
    return request("PATCH", url, JSON.stringify(body), false, { type: "DEFAULT" }, opts);
};

export const apiPatchGet = async <T, R>(url: string, body: R, opts?: RequestOpts): Promise<FetchResponse<T>> => {
    return request<T>("PATCH", url, JSON.stringify(body), false, { type: "DEFAULT" }, opts);
};

export const apiDelete = async (url: string, opts?: RequestOpts): Promise<NoDataResponse> => {
    return request("DELETE", url, undefined, false, { type: "DEFAULT" }, opts);
};

export const apiGetMultiplePages = async <T>(url: string, opts?: RequestOpts): Promise<FetchResponse<T[]>> => {
    type DataPage<T> = {
        count: number;
        next: string | null;
        previous: string | null;
        results: T[];
    };

    const allResults: T[] = [];
    let nextUrl: string | null = url;
    do {
        if (nextUrl != null) {
            const returnData: FetchResponse<DataPage<T>> = await apiGet<DataPage<T>>(nextUrl, opts);
            if (returnData.responseType === "Success") {
                nextUrl = returnData.data.next;
                allResults.push(...returnData.data.results);
            } else if (returnData.responseType === "Error") {
                return returnData;
            } else {
                throw new Error("The query didn't return a success or error response.");
            }
        }
    } while (nextUrl !== null);

    return { responseType: "Success", data: allResults };
};