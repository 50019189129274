import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { addSeconds } from "date-fns";
import reducer, {
    IAuth,
    IAction,
    IBackupCode,
    ISmsDevice,
    ITotpDevice,
    initialState,
    IAuthorization,
    removeLandingPageStorage,
} from "reducers/auth";
import { NotificationContext } from "contexts/notification";
import { exhaustive } from "exhaustive";
import { apiDelete, apiGet, apiGetMultiplePages, apiPatchGet, apiPost, apiPostGet } from "fetchApi";

export const LOGOUT_DELAY = 30 * 60 * 1000; // 30 minutes

export const AuthContext = createContext<IAuth>({
    ...initialState,
});

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);

    const [currentState, dispatch] = useReducer<Reducer<IAuth, IAction>>(reducer, initialState);

    const login = useCallback(
        async (username: string, password: string): Promise<IAuthorization> => {
            clearAuthState();
            dispatch({ type: "FETCH_CREDENTIALS" });
            interface LoginCredentials {
                username: string,
                password: string,
            }
            const data: LoginCredentials = {
                username,
                password,
            };
            const returnData = await apiPostGet<IAuthorization, LoginCredentials>("/auth/login/", data, { excludeCredentials: true });
            const result = exhaustive(returnData, "responseType", {
                Success: (it) => {
                    const parsedData = {
                        otp_required: it.data.otp_required,
                        expires: addSeconds(new Date(), it.data.expires_in).toISOString(),
                    };
                    dispatch({
                        type: "FETCH_CREDENTIALS_SUCCESS",
                        sessionStore: parsedData,
                    });
                    removeLandingPageStorage();
                    return it.data;
                },
                Error: (error) => {
                    if (error.code === 400) {
                        notification.enqueNotification("error_login_400");
                    } else if (error.code === 403) {
                        notification.enqueNotification("error_login_403");
                    } else {
                        notification.enqueNotification("error_login_no_response");
                    }
                    dispatch({ type: "FETCH_CREDENTIALS_FAILURE" });
                    return {} as IAuthorization;
                },
            });
            return result;
        },
        [notification]
    );

    const logout = useCallback(async (): Promise<boolean> => {
        if (currentState.isLoggingOut) {
            return true;
        }
        dispatch({ type: "LOGOUT" });
        const response = await apiGet<unknown>("/auth/logout/");
        exhaustive(response, "responseType", {
            Success: (it) => {
                dispatch({ type: "LOGOUT_SUCCESS" });
            },
            Error: () => {
                dispatch({ type: "LOGOUT_FAILURE" });
                return false;
            },
        });
        dispatch({ type: "CLEAR_CREDENTIALS" });
        return true;
    }, [currentState.isLoggingOut]);

    const setPassword = useCallback(
        async (
            password: string,
            rePassword: string,
            token: string,
            sendNewsByMail: boolean
        ): Promise<Record<string, unknown> | boolean> => {
            dispatch({ type: "SET_PASSWORD" });
            const data = {
                password,
                re_password: rePassword,
                token,
                send_news_by_mail: sendNewsByMail,
            };
            const returnData = await apiPostGet<Record<string, unknown>, object>("/contact-persons/register/", data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "SET_PASSWORD_SUCCESS" });
                    notification.enqueNotification("success_saveNewPassword");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_saveNewPassword", error);
                    dispatch({ type: "SET_PASSWORD_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const forgotPassword = useCallback(
        async (email: string): Promise<boolean> => {
            dispatch({ type: "FORGOT_PASSWORD" });
            const data = {
                email,
            };
            const response = await apiPost<object>("/auth/reset-password/", data);

            return exhaustive(response, "responseType", {
                Success: (it) => {
                    notification.enqueNotification("success_sentResetPassword");
                    dispatch({ type: "FORGOT_PASSWORD_SUCCESS" });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_sendPasswordLink", error);
                    dispatch({ type: "FORGOT_PASSWORD_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const resetPassword = useCallback(
        async (new_password: string, uuid: string): Promise<number> => {
            dispatch({ type: "RESET_PASSWORD" });
            const data = {
                new_password,
                uuid,
            };

            const response = await apiPost<object>("/auth/new-password/", data);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "RESET_PASSWORD_SUCCESS" });
                    notification.enqueNotification("success_resetPassword");
                    return 200;
                },
                Error: (error) => {
                    const response_status = error.code ? error.code : 0;
                    if (response_status !== 412) {
                        notification.enqueNotification("error_resetPassword", error);
                    }

                    dispatch({ type: "RESET_PASSWORD_FAILURE" });
                    return response_status;
                },
            });

        },
        [notification]
    );

    const fetchBackupCodes = useCallback(async (): Promise<IBackupCode[] | boolean> => {
        dispatch({ type: "FETCH_BACKUP_CODES" });
        const returnData = await apiGetMultiplePages<IBackupCode>("/auth/otp/backup-codes/");
        return exhaustive(returnData, "responseType", {
            Success: (it) => {
                dispatch({
                    type: "FETCH_BACKUP_CODES_SUCCESS",
                    codes: it.data,
                });
                return true;
            },
            Error: (error) => {
                notification.enqueNotification("error_fetchBackupCodes", error);
                dispatch({ type: "FETCH_BACKUP_CODES_FAILURE" });
                return false;
            },
        });
    }, [notification]);

    const generateBackupCodes = useCallback(async (): Promise<boolean> => {
        dispatch({ type: "GENERATE_BACKUP_CODES" });
        const response = await apiPostGet<IBackupCode[], undefined>("/auth/otp/backup-codes/", undefined);

        return exhaustive(response, "responseType", {
            Success: (it) => {
                dispatch({ type: "GENERATE_BACKUP_CODES_SUCCESS", codes: it.data });
                return true;
            },
            Error: (error) => {
                notification.enqueNotification("error_generateBackupCodes", error);
                dispatch({ type: "GENERATE_BACKUP_CODES_FAILURE" });
                return false;
            },
        });
    }, [notification]);

    const fetchTotpDevices = useCallback(async (): Promise<boolean> => {
        dispatch({ type: "FETCH_TOTP_DEVICES" });
        const returnData = await apiGetMultiplePages<ITotpDevice>("/auth/otp/totp/devices/");
        return exhaustive(returnData, "responseType", {
            Success: (it) => {
                dispatch({
                    type: "FETCH_TOTP_DEVICES_SUCCESS",
                    devices: it.data,
                });
                return true;
            },
            Error: (error) => {
                notification.enqueNotification("error_fetchTotpDevices", error);
                dispatch({ type: "FETCH_TOTP_DEVICES_FAILURE" });
                return false;
            },
        });
    }, [notification]);

    const createTotpDevice = useCallback(
        async (name: string, key?: string): Promise<boolean> => {
            dispatch({ type: "CREATE_TOTP_DEVICE" });
            const data = {
                name: name,
                key: key,
            };
            const returnData = await apiPostGet<ITotpDevice, object>("/auth/otp/totp/devices/", data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "CREATE_TOTP_DEVICE_SUCCESS", device: it.data });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_createTotpDevice", error);
                    dispatch({ type: "CREATE_TOTP_DEVICE_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const updateTotpDevice = useCallback(
        async (id: number, name: string): Promise<boolean> => {
            dispatch({ type: "UPDATE_TOTP_DEVICE" });
            const data = {
                name: name,
            };
            const returnData = await apiPatchGet<ITotpDevice, object>(`/auth/otp/totp/devices/${id}/`, data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_TOTP_DEVICE_SUCCESS", id: id, device: it.data });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateTotpDevice", error);
                    dispatch({ type: "UPDATE_TOTP_DEVICE_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const deleteTotpDevice = useCallback(
        async (id: number): Promise<boolean> => {
            dispatch({ type: "DELETE_TOTP_DEVICE" });
            const response = await apiDelete(`/auth/otp/totp/devices/${id}/`);
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "DELETE_TOTP_DEVICE_SUCCESS", id: id });
                    notification.enqueNotification("success_removeTotpDevice");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_removeTotpDevice", error.code);
                    dispatch({ type: "DELETE_TOTP_DEVICE_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const fetchSmsDevices = useCallback(async (): Promise<boolean> => {
        dispatch({ type: "FETCH_SMS_DEVICES" });
        const returnData = await apiGetMultiplePages<ISmsDevice>("/auth/otp/sms/devices/");
        return exhaustive(returnData, "responseType", {
            Success: (it) => {
                dispatch({
                    type: "FETCH_SMS_DEVICES_SUCCESS",
                    devices: it.data,
                });
                return true;
            },
            Error: (error) => {
                notification.enqueNotification("error_fetchSmsDevices", error);
                dispatch({ type: "FETCH_SMS_DEVICES_FAILURE" });
                return false;
            },
        });
    }, [notification]);

    const createSmsDevice = useCallback(
        async (name: string, number: string, key?: string): Promise<boolean> => {
            dispatch({ type: "CREATE_SMS_DEVICE" });
            interface ICreateSimDevice {
                name: string,
                number: string,
                key: string | undefined,
            }
            const data: ICreateSimDevice = {
                name: name,
                number: number,
                key: key,
            };
            const returnData = await apiPostGet<ISmsDevice, ICreateSimDevice>("/auth/otp/sms/devices/", data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "CREATE_SMS_DEVICE_SUCCESS", device: it.data });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_createSmsDevice", error);
                    dispatch({ type: "CREATE_SMS_DEVICE_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const updateSmsDevice = useCallback(
        async (id: number, number: string, name?: string): Promise<boolean> => {
            dispatch({ type: "UPDATE_SMS_DEVICE" });
            interface UpdateSmsDevicePost {
                number: string,
                name?: string,
            }
            const data: UpdateSmsDevicePost = {
                number: number
            };
            if (number) {
                data.name = name;
            }
            const returnData = await apiPatchGet<ISmsDevice, UpdateSmsDevicePost>(`/auth/otp/sms/devices/${id}/`, data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_SMS_DEVICE_SUCCESS", id: id, device: it.data });
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateSmsDevice", error);
                    dispatch({ type: "UPDATE_SMS_DEVICE_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const deleteSmsDevice = useCallback(
        async (id: number): Promise<boolean> => {

            dispatch({ type: "DELETE_SMS_DEVICE" });
            const response = await apiDelete(`/auth/otp/sms/devices/${id}/`);

            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({ type: "DELETE_SMS_DEVICE_SUCCESS", id: id });
                    notification.enqueNotification("success_removeSmsDevice");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_removeSmsDevice", error);
                    dispatch({ type: "DELETE_SMS_DEVICE_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const sendAuthenticationSMS = useCallback(
        async (key?: string): Promise<string> => {
            dispatch({ type: "SEND_SMS" });
            const noticedNumber = await apiPostGet<string, object>("/auth/otp/sms/send/", { key });

            return exhaustive(noticedNumber, "responseType", {
                Success: (it) => {
                    dispatch({ type: "SEND_SMS_SUCCESS" });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_sendSms", error.code);
                    dispatch({ type: "SEND_SMS_FAILURE" });
                    return "";
                },
            });
        },
        [notification]
    );

    const clearAuthState = useCallback((): void => {
        dispatch({ type: "CLEAR_CREDENTIALS" });
    }, []);

    const verifyOtpCode = useCallback(async (data: { token: string; key?: string }): Promise<boolean> => {
        let parsedData = {};
        dispatch({ type: "VERIFY_OTP_CODE" });
        const response = await apiPostGet<IAuthorization, { token: string; key?: string }>("/auth/otp/verify/", data);
        return exhaustive(response, "responseType", {
            Success: (it) => {
                if (it.data.expires_in) {
                    parsedData = {
                        expires: addSeconds(new Date(), it.data.expires_in).toISOString(),
                    };
                }

                dispatch({ type: "VERIFY_OTP_CODE_SUCCESS", sessionStore: parsedData });
                return true;
            },
            Error: () => {
                dispatch({ type: "VERIFY_OTP_CODE_FAILURE" });
                return false;
            },
        });
    }, []);

    const refreshCredentials = useCallback(async (): Promise<boolean> => {
        try {
            dispatch({ type: "REFRESH_CREDENTIALS" });
            const returnData = await apiPostGet<object, object>("/auth/refresh-token/", {});
            if (returnData.responseType === "Success") {
                if ("expires_in" in returnData.data && typeof returnData.data.expires_in === "number") {
                    const parsedData = {
                        expires: addSeconds(new Date(), returnData.data.expires_in).toISOString(),
                    };

                    dispatch({ type: "REFRESH_CREDENTIALS_SUCCESS", sessionStore: parsedData });
                    return true;
                } else {
                    throw new Error("Could not update expiry time of session");
                }
            }
            return false;
        } catch (error) {
            dispatch({ type: "REFRESH_CREDENTIALS_FAILURE" });
            logout();
            return false;
        }
    }, [logout]);

    const value = useMemo(() => {
        return {
            ...currentState,
            login,
            logout,
            setPassword,
            forgotPassword,
            resetPassword,
            fetchBackupCodes,
            generateBackupCodes,
            fetchSmsDevices,
            createSmsDevice,
            updateSmsDevice,
            deleteSmsDevice,
            sendAuthenticationSMS,
            fetchTotpDevices,
            createTotpDevice,
            updateTotpDevice,
            deleteTotpDevice,
            verifyOtpCode,
            clearAuthState,
            refreshCredentials,
        };
    }, [
        currentState,
        login,
        logout,
        setPassword,
        forgotPassword,
        resetPassword,
        fetchBackupCodes,
        generateBackupCodes,
        fetchSmsDevices,
        createSmsDevice,
        updateSmsDevice,
        deleteSmsDevice,
        sendAuthenticationSMS,
        fetchTotpDevices,
        createTotpDevice,
        updateTotpDevice,
        deleteTotpDevice,
        verifyOtpCode,
        clearAuthState,
        refreshCredentials,
    ]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

interface IConsumer {
    component: FC<{ auth: IAuth }>;
    props?: Record<string, unknown>;
}
export const AuthConsumer: FC<IConsumer> = ({ component, props = {} }) => {
    return (
        <AuthContext.Consumer>
            {(auth) => {
                return <>{component({ auth: { ...props, ...auth } })}</>;
            }}
        </AuthContext.Consumer>
    );
};
