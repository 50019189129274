import { exhaustive } from "exhaustive";
import { apiGet } from "fetchApi";
import React, { useCallback, useMemo } from "react";
import reducer, {
    DomainContentType,
    IAction,
    IDomainUser,
    IGroup,
    initialState,
    IRole,
    IRoleManagement,
} from "reducers/roleManagement";
import { emptyPaginationActionData, TPaginationActionData } from "utils/paginationStore";

export const RoleManagementContext = React.createContext<IRoleManagement>({
    ...initialState,
});

export const RoleManagementProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [currentState, dispatch] = React.useReducer<React.Reducer<IRoleManagement, IAction>>(reducer, initialState);

    const fetchRolesOfDomain = useCallback(
        async (
            contentType: DomainContentType,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IRole>> => {
            dispatch({ type: "FETCH_ROLES" });
            const response = await apiGet<TPaginationActionData<IRole>>(`/domains/${contentType}/roles/`, { params });
            return exhaustive(response, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_ROLES_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_ROLES_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchGroupsOfDomain = useCallback(
        async (
            contentType: DomainContentType,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IGroup>> => {
            dispatch({ type: "FETCH_GROUPS" });
            const returnData = await apiGet<TPaginationActionData<IGroup>>(`/domains/${contentType}/groups/`, { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_GROUPS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_GROUPS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const fetchUsersOfDomain = useCallback(
        async (
            contentType: DomainContentType,
            params: Record<string, unknown>
        ): Promise<TPaginationActionData<IDomainUser>> => {
            dispatch({ type: "FETCH_USERS" });
            const returnData = await apiGet<TPaginationActionData<IDomainUser>>(`/domains/${contentType}/users/`, { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_USERS_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    dispatch({ type: "FETCH_USERS_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        []
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchRolesOfDomain,
            fetchGroupsOfDomain,
            fetchUsersOfDomain,
        };
    }, [currentState, fetchRolesOfDomain, fetchGroupsOfDomain, fetchUsersOfDomain]);

    return <RoleManagementContext.Provider value={value}>{children}</RoleManagementContext.Provider>;
};
