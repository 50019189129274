import { fetchDynamicTags } from "adapters/languageAdapter";
import { format, parseISO } from "date-fns";
import { sv as language_sv } from "localization/languages/sv";
import { en as language_en } from "localization/languages/en";
import { sv as notifications_sv } from "localization/notifications/sv";
import { en as notifications_en } from "localization/notifications/en";
import React, { FC, ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { dateFormats } from "utils/formats";
import { exhaustive } from "exhaustive";

export interface IExternalTag {
    identifier: string;
    text: string;
}

export type TLanguage = "sv" | "en";
export type TLanguageDefinition = typeof language_sv;
export type TLanguageTag = keyof TLanguageDefinition;

export type TNotificationDefinition = typeof notifications_sv;
export type TNotificationTag = keyof TNotificationDefinition;

const definitions: Record<TLanguage, TLanguageDefinition> = {
    sv: language_sv,
    en: language_en,
};
const notificationsDefinitions: Record<TLanguage, TNotificationDefinition> = {
    sv: notifications_sv,
    en: notifications_en,
};

const getCurrentLanguage = (): TLanguage => {
    const storedLanguage = localStorage.getItem("lang");
    let currentLanguage = "";

    if (storedLanguage) {
        currentLanguage = storedLanguage;
    } else {
        const navLanguage = navigator.language.substring(0, 2).toLowerCase();
        if (navLanguage !== "sv" && navLanguage !== "en") {
            currentLanguage = "sv";
        } else {
            currentLanguage = navLanguage;
        }
        localStorage.setItem("lang", currentLanguage);
    }
    return currentLanguage as TLanguage;
};

export const getTranslation = (definition: TLanguageDefinition, tag: string): string => {
    const translation = definition[tag];
    if (!translation) {
        return `[${tag}]`;
    }

    if (typeof translation === "string") {
        return translation;
    }

    return translation.join(" ");
};

export const replaceHashtags = (text: string, mapObj?: Record<string, string>): string => {
    if (!mapObj) {
        return text;
    }

    const re = new RegExp("#" + Object.keys(mapObj).join("|#"), "gi");
    return text.replace(re, (matched) => mapObj[matched.substring(1)]);
};

export interface IState {
    currentLanguage: TLanguage;
    definition: TLanguageDefinition;
    notificationDefinition: TNotificationDefinition;
    changeLanguage: (language: string) => void;
    formatFromToDate: (fromDate: string, toDate?: string) => string;
    localize: (tag: TLanguageTag) => string;
}

const initialState: IState = {
    currentLanguage: getCurrentLanguage(),
    definition: definitions[getCurrentLanguage()],
    notificationDefinition: notificationsDefinitions[getCurrentLanguage()],
    changeLanguage: () => { },
    formatFromToDate: () => "",
    localize: () => "",
};

export const LanguageContext = createContext<IState>(initialState);

export const LanguageProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const [currentState, setCurrentState] = useState<IState>(initialState);

    useEffect(() => {
        (async (): Promise<void> => {
            const response = await fetchDynamicTags({ language: currentState.currentLanguage }).then((res) => {
                return exhaustive(res, "responseType", {
                    Success: (it) => {
                        return it.data;
                    },
                    Error: () => {
                        return [] as IExternalTag[];
                    },
                });
            });
            const tags = response;

            setCurrentState((previousState) => {
                const reducedTags = tags.reduce((prevTag, currentTag) => {
                    return { ...prevTag, [currentTag.identifier]: currentTag.text };
                }, previousState.definition);

                return { ...previousState, ...{ definition: reducedTags } };
            });
        })();
    }, [currentState.currentLanguage]);

    const changeLanguage = useCallback((language: string): void => {
        localStorage.setItem("lang", language);
        window.location.reload();
    }, []);

    const formatFromToDate = useCallback((fromDate: string, toDate?: string): string => {
        if (toDate) {
            return `${format(parseISO(fromDate ?? ""), dateFormats.WEBDATE)} ${initialState.definition["to"]} ${format(
                parseISO(toDate ?? ""),
                dateFormats.WEBDATE
            )}`;
        }
        return `${format(parseISO(fromDate ?? ""), dateFormats.WEBDATE)} - ${initialState.definition[
            "untilFurtherNotice"
        ].toLowerCase()}`;
    }, []);

    const localize = useCallback(
        (tag: TLanguageTag, mapObj?: Record<string, string>) => {
            return replaceHashtags(getTranslation(currentState.definition, tag), mapObj);
        },
        [currentState.definition]
    );

    const value = useMemo(() => {
        return { ...currentState, changeLanguage, formatFromToDate, localize };
    }, [currentState, changeLanguage, formatFromToDate, localize]);

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export const LanguageConsumer = LanguageContext.Consumer;
