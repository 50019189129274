import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { NotificationContext } from "contexts/notification";
import reducer, {
    IAction,
    IBookkeepingAccount,
    initialState,
    IProduct,
    IProducts,
    IServiceSection,
} from "reducers/products";
import { apiGet, apiPatchGet, apiPostGet } from "fetchApi";
import { exhaustive } from "exhaustive";

export const ProductsContext = createContext<IProducts>({
    ...initialState,
});

export const ProductsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IProducts, IAction>>(reducer, initialState);

    const fetchProduct = useCallback(
        async (productKey: string, skipErrorNotification = false): Promise<IProduct> => {
            dispatch({ type: "FETCH_PRODUCT" });
            const returnData = await apiGet<IProduct>(`/products/${productKey}/`);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_PRODUCT_SUCCESS",
                        product: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    if (!skipErrorNotification) {
                        notification.enqueNotification("error_fetchProduct", error);
                    }
                    dispatch({ type: "FETCH_PRODUCT_FAILURE" });
                    return {} as IProduct;
                },
            });
        },
        [notification]
    );

    const updateProduct = useCallback(
        async (productKey: string, data: Record<string, unknown>): Promise<IProduct> => {
            dispatch({ type: "UPDATE_PRODUCT" });
            const returnData = await apiPatchGet<IProduct, Record<string, unknown>>(`/products/${productKey}/`, data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "UPDATE_PRODUCT_SUCCESS", product: it.data });
                    notification.enqueNotification("success_updateProduct");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateProduct", error);
                    dispatch({ type: "UPDATE_PRODUCT_FAILURE" });
                    return {} as IProduct;
                },
            });
        },
        [notification]
    );

    const createProduct = useCallback(
        async (data: Record<string, unknown>): Promise<IProduct> => {

            dispatch({ type: "CREATE_PRODUCT" });
            const returnData = await apiPostGet<IProduct, Record<string, unknown>>("/products/", data);

            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "CREATE_PRODUCT_SUCCESS", product: it.data });
                    notification.enqueNotification("success_createProduct");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_createProduct", error);
                    dispatch({ type: "CREATE_PRODUCT_FAILURE" });
                    return {} as IProduct;
                },
            });
        },
        [notification]
    );

    const fetchAccounts = useCallback(
        async (params?: Record<string, unknown>): Promise<IBookkeepingAccount[]> => {
            dispatch({ type: "FETCH_ACCOUNTS" });
            const returnData = await apiGet<IBookkeepingAccount[]>("/products/accounts/", { params, });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_ACCOUNTS_SUCCESS",
                        accounts: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    notification.enqueNotification("error_fetchBookkeepingAccounts");
                    dispatch({ type: "FETCH_ACCOUNTS_FAILURE" });
                    return [] as IBookkeepingAccount[];
                },
            });
        },
        [notification]
    );

    const fetchServiceSections = useCallback(
        async (params?: Record<string, unknown>): Promise<IServiceSection[]> => {
            dispatch({ type: "FETCH_SERVICE_SECTIONS" });
            const returnData = await apiGet<IServiceSection[]>("/products/service-sections/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_SERVICE_SECTIONS_SUCCESS",
                        serviceSections: it.data,
                    });
                    return it.data;
                },
                Error: () => {
                    notification.enqueNotification("error_fetchServiceAccounts");
                    dispatch({ type: "FETCH_SERVICE_SECTIONS_FAILURE" });
                    return [] as IServiceSection[];
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchProduct,
            updateProduct,
            createProduct,
            fetchAccounts,
            fetchServiceSections,
        };
    }, [currentState, fetchProduct, updateProduct, createProduct, fetchAccounts, fetchServiceSections]);

    return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};
