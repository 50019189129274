import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import reducer, { INewsArticles, IAction, INewsArticle, initialState } from "reducers/newsArticles";
import { NotificationContext } from "contexts/notification";
import { TPaginationActionData, emptyPaginationActionData } from "utils/paginationStore";
import { apiGet } from "fetchApi";
import { exhaustive } from "exhaustive";

export const NewsArticleContext = createContext<INewsArticles>({
    ...initialState,
});

export const NewsArticleProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<INewsArticles, IAction>>(reducer, initialState);

    const fetchNews = useCallback(
        async (params?: Record<string, unknown>): Promise<TPaginationActionData<INewsArticle>> => {
            dispatch({ type: "FETCH_NEWS_LIST" });
            const returnData = await apiGet<TPaginationActionData<INewsArticle>>("/news/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({ type: "FETCH_NEWS_LIST_SUCCESS", data: it.data });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchNewsList", error);
                    dispatch({ type: "FETCH_NEWS_LIST_FAILURE" });
                    return emptyPaginationActionData;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            fetchNews,
        };
    }, [currentState, fetchNews]);

    return <NewsArticleContext.Provider value={value}>{children}</NewsArticleContext.Provider>;
};
