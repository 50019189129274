
import { apiGet, apiPost, FetchResponse, NoDataResponse } from "fetchApi";

export const sendTaskAction = async (taskNumber: number, message: string): Promise<NoDataResponse> => {
    const data = {
        task_number: taskNumber,
        message: message,
    };
    return apiPost<object>("/vk_data/tasks/actions/", data);
};

export const checkTaskStatus = async (uuid: string): Promise<FetchResponse<unknown>> => {
    return apiGet<unknown>(`/configurations/task-results/${uuid}`);
};