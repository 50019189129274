import { apiDelete, apiGet, FetchResponse, NoDataResponse } from "fetchApi";

export const renewRepresentitiveInvitationAdapter = async (
    id: number,
): Promise<FetchResponse<any>> => {
    return apiGet<any>(`/communities/representative-invitations/${id}/renew/`);
};

export const deleteRepresentitiveInvitationAdapter = async (
    id: number,
): Promise<NoDataResponse> => {
    return apiDelete(`/communities/representative-invitations/${id}/`);
};