import { exhaustive } from "exhaustive";
import { apiFormDataPostGet, apiGet, FetchResponse } from "fetchApi";

interface DocumentTypes {
    memberships: MembershipDocument;
}

export interface MembershipDocument {
    uuid: string;
    created: string;
    document_nr: string;
    document_file: string;
    document_type: MembershipDocumentTypes;
    membership: number;
}
export const membershipDocumentTypesArr = ["agreement", "other"] as const;
export type MembershipDocumentTypes = typeof membershipDocumentTypesArr[number];

export const fetchDocuments = async <T extends keyof DocumentTypes>(
    type: T,
    id: string
): Promise<DocumentTypes[T][]> => {
    const response = await apiGet<DocumentTypes[T][]>(`/documents/${type}/`, { params: { membership_id: id } });
    return exhaustive(response, "responseType", {
        Success: (it) => {
            return it.data;
        },
        Error: () => {
            return [] as DocumentTypes[T][];
        },
    });
};

export const uploadDocument = async <T extends keyof DocumentTypes>(
    type: T,
    formData: FormData
): Promise<DocumentTypes[T] | null> => {
    const response: FetchResponse<DocumentTypes[T]> = await apiFormDataPostGet<DocumentTypes[T]>(`/documents/${type}/`, formData);
    const res = exhaustive(response, "responseType", {
        Success: (it) => {
            return it.data;
        },
        Error: () => {
            return {} as DocumentTypes[T];
        },
    });
    return res;
};
